import { Injectable } from '@angular/core';
import { ApiAbstractService } from './api-abstract.service';
import { PatientInfoType } from '@srcapp/types/apiResponse.type';


@Injectable({
  providedIn: 'root',
})
export class ApiPatientService  extends ApiAbstractService {

  /**
   * リストを取得する処理
   */
  getList() {
    return this.get(`${this.getBaseApi()}/api/v1/patients`);
    // const res = {"msg":"success.","statusCode":0,"patientList":[
    //   {"patientId":"5e1b607229e4852324c52d34","patientCode":"USER1001","name":"品川 太郎","nameKana":"ｼﾅｶﾞﾜ ﾀﾛｳ","gender":"MAN","birthday":"19580304","wardCode":"","wardName":"","unitCode":"002","unitName":"２階","roomName":"部屋２０１","bedNo":"部屋２０１"},
    //   {"patientId":"5e1b609f29e4852324c52d36","patientCode":"USER1002","name":"品川 花子","nameKana":"ｼﾅｶﾞﾜ ﾊﾅｺ","gender":"WOMAN","birthday":"1958-05-04","wardCode":"","wardName":"","unitCode":"002","unitName":"２階","roomName":"部屋２０２","bedNo":"部屋２０２"},{"patientId":"5e1d44c3e896a0e84b26ef13","patientCode":"USER1003","name":"鈴木 三郎","nameKana":"ｽｽﾞｷ ｻﾌﾞﾛｳ","gender":"WOMAN","birthday":"1958-05-04","wardCode":"","wardName":"","unitCode":"002","unitName":"２階","roomName":"部屋２０３","bedNo":"部屋２０３"},{"patientId":"5e1d44cde896a0e84b26ef20","patientCode":"USER1004","name":"鈴木 四郎","nameKana":"ｽｽﾞｷ ｼﾛｳ","gender":"MAN","birthday":"1958-05-04","wardCode":"","wardName":"","unitCode":"002","unitName":"２階","roomName":"部屋２０５","bedNo":"部屋２０５"}],"expired":1592153296};
    // return Promise.resolve(res);
  }

  /**
   * 利用者情報を検索するAPI
   * 
   *  - 検索に見つかった利用者IDを返す
   * 
   * @return Promise<any>
   */
  search(query): Promise<any> {
    let queryString = Object.keys(query).map((key) => `${key}=${query[key]}`).join('&');
    return this.get(`${this.getBaseApi()}/api/v1/patientsSearch?${queryString}`);
  }

  create(patient: Partial<PatientInfoType>): Promise<any> {
    return this.post(this.getBaseApi() + '/api/v1/patient', patient);
  }

  update(patient): Promise<any> {
    return this.post(this.getBaseApi() + '/api/v1/patient/' + patient.patientId, patient);
  }

  deleteById(patientId) {
    return this.delete(this.getBaseApi() + '/api/v1/patient/' + patientId);
  }
}


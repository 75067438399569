import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { VitalService } from '@srcapp/services/vital/vital.service'

// 代表的な設定オプション。highchartsで受け入れるものなら任意で追加して良い
export interface VitalChartOptions {
  chart: {
    type?: string;
    margin?: number | number[];
    width?: number | string;
    height?: number | string;
    styleMode?: boolean;
    animation?: boolean | {};
  };
  xAxis: object[];
  yAxis: object[];
  title: {
    text?: string;
  };
  legend?: {};
  credits?: {};
  time?: {};
  colors?: string[];
  plotOptions?: {};
}
export type latestData = null | {
  value: number,
  time: number,
}
export interface LatestDataList {
  BT?: latestData;
  BPH?: latestData;
  BPL?: latestData;
  PR?: latestData;
  SpO2?: latestData;
  RR?: latestData;
  BS?: latestData;
  BH?: latestData;
  BW?: latestData;
}
type VitalTypes = 'BT' | 'BP' | 'PR' | 'SpO2' | 'RR' | 'BS' | 'BH' | 'BW';

@Component({
  selector: 'app-vital-chart',
  templateUrl: './vital-chart.component.html',
  styleUrls: ['./vital-chart.component.scss'],
})
export class VitalChartComponent implements OnInit, AfterViewInit {
  @Input() chartId = '';
  _chartId = '';
  // 左軸に紐づくバイタル
  @Input() left: VitalTypes[] = [];
  // 右軸に紐づくバイタル
  @Input() right: VitalTypes[] = [];
  @Input() patientId;
  @Input() options: Partial<VitalChartOptions> = {};

  // デフォルトでは1週間分のデータをとる
  @Input() start = moment().subtract(7, 'days').startOf('days').unix();
  @Input() end = moment().endOf('days').unix();

  @Output() updateLatestData = new EventEmitter<LatestDataList>();

  intervalId;

  defaultOptions: Partial<VitalChartOptions> = {
    chart: {
      type: 'line',
      margin: [10, 50, 55, 50],
      animation: false,
    },
    title: null,
    // 凡例
    legend: {
      enabled: false,
    },
    // クレジット表記
    credits: {
      enabled: false,
    },
    colors: ['orange', '#ff6666', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
    // 横軸：時間
    xAxis: [
      {
        type: 'datetime',
        title: null,
        tickInterval: 24 * 60 * 60 * 1000, // 1日
        dateTimeLabelFormats: {
          // day: '%m月%d日',
          day: '%d日',
          hour: '%d日%k時',
          minute: '%k時',
          second: '%k時',
          millisecond: '%k時',
        },
        max: this.end * 1000,
        min: this.start * 1000,
      },
    ],
    // 縦軸は内容ごとに
    // yAxis: {},
    // データ描画オプション
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 5,
        },
        fillOpacity: 0.5,
      },
      line: {
        dataLabels: {
          enabled: true,
          align: 'center',
          y: -5, // 上に上げる時がマイナスなので注意
        },
      },
    },
    time: {
      timezoneOffset: -9 * 60,
    },
  };
  // 内容ごとのグラフ設定
  optionByVitalType = {
    BT: {
      yAxis: {
        title: '体温',
        tickInterval: 1,
        allowDecimals: true,
        labels: {
          format: `{value}`,
        },
        max: 41,
        min: 34,
        id: 'BT',
      },
      tooltip: {
        xDateFormat: '%m/%d %H:%M',
        pointFormat: '体温 {point.y}度',
      },
    },
    BP: {
      yAxis: {
        title: '血圧',
        tickInterval: 50,
        allowDecimals: true,
        labels: {
          format: `{value}`,
        },
        max: 250,
        min: 50,
        id: 'BP',
      },
      tooltip: {
        xDateFormat: '%m/%d %H:%M',
        pointFormat: '血圧 {point.y}',
      },
    },
    PR: {
      yAxis: {
        title: '脈',
        tickInterval: 40,
        labels: {
          format: `{value}`,
        },
        max: 200,
        min: 40,
        id: 'PR',
      },
      tooltip: {
        xDateFormat: '%m/%d %H:%M',
        pointFormat: '脈 {point.y}回',
      },
    },
    SpO2: {
      yAxis: {
        title: 'SpO2',
        tickInterval: 5,
        labels: {
          format: `{value}`,
        },
        max: 100,
        min: 70,
        id: 'SpO2',
      },
      tooltip: {
        xDateFormat: '%m/%d %H:%M',
        pointFormat: 'SpO2 {point.y}%',
      },
    },
    RR: {
      yAxis: {
        title: '呼吸',
        tickInterval: 10,
        labels: {
          format: `{value}`,
        },
        max: 60,
        min: 0,
        id: 'RR',
      },
      tooltip: {
        xDateFormat: '%m/%d %H:%M',
        pointFormat: '呼吸 {point.y}回',
      },
    },
    BS: {
      yAxis: {
        title: '血糖値',
        tickInterval: 100,
        labels: {
          format: `{value}`,
        },
        max: 500,
        min: 50,
        id: 'BS',
      },
      tooltip: {
        xDateFormat: '%m/%d %H:%M',
        pointFormat: '血糖値 {point.y}mg/dL',
      },
    },
    BH: {
      yAxis: {
        title: '身長',
        tickInterval: 20,
        labels: {
          format: `{value}`,
        },
        max: 180,
        min: 100,
        id: 'BH',
      },
      tooltip: {
        xDateFormat: '%m/%d %H:%M',
        pointFormat: '身長 {point.y}cm',
      },
    },
    BW: {
      yAxis: {
        title: '体重',
        tickInterval: 20,
        labels: {
          format: `{value}`,
        },
        max: 140,
        min: 20,
        id: 'BW',
      },
      tooltip: {
        xDateFormat: '%m/%d %H:%M',
        pointFormat: '体重 {point.y}kg',
      },
    },
  }

  // グラフオブジェクト
  _chart: Highcharts.Chart | null;

  constructor(
    private vitalService: VitalService,
  ) {}

  ngOnInit() {
    const str = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let random_str = '';
    for (let i = 0; i < 20; i++) {
      random_str += str[Math.floor(Math.random() * str.length)];
    }
    this._chartId = this.chartId + '_' + random_str;
  }

  ngAfterViewInit() {
    this.render();
  }

  initChart() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this._chart) {
      this._chart.destroy();
    }
  }

  // Inputが変化した場合は、検索条件を変更して再描画
  ngOnChanges(changes: SimpleChanges) {
    this.render();
  }

  reflow() {
    if (this._chart) {
      this._chart.reflow();
    }
  }
  // 外部から明示的にサイズ変更をしたいとき
  resize(setting: {
    width?: number;
    height?: number;
  }) {
    if (this._chart) {
      const newWidth = setting.width ? setting.width : this._chart.chartWidth;
      const newHeight = setting.height ? setting.height : this._chart.chartHeight;
      this._chart.setSize(newWidth, newHeight, false);
    }
  }

  async render() {
    this.initChart();
    const series = await this.getData();
    // 描画するデータに応じたオプションを用いる
    const yAxis = [];
    this.left.map((type) => {
      yAxis.push(this.optionByVitalType[type].yAxis);
    });
    this.right.map((type) => {
      const axis: {
        [k: string]: any;
      } = Object.assign({}, this.optionByVitalType[type].yAxis);
      axis.opposite = true;
      yAxis.push(axis);
    });
    const option: any = {
      ...this.defaultOptions,
      yAxis: yAxis,
      ...this.options,
      series: series,
    };
    option.xAxis[0].min = this.start * 1000;
    option.xAxis[0].max = this.end * 1000;
    // 描画
    this._chart = Highcharts.chart(this._chartId, option, (chart) => {
        // 描画直後にreflowさせるとなぜか効かないので、しばらくreflowを続けるが、他のやり方でどうにかしたい...
        let wait = 5000;
        const interval = 1000;
        this.intervalId = setInterval(() => {
          if (this._chart && this._chart.hasOwnProperty('angular')) {
            this._chart.reflow();
            wait -= interval;
            if (wait <= 0) {
              clearInterval(this.intervalId);
            }
          }
        }, interval);
    });
  }
  // データ取得処理
  async getData(){
    const types = [];
    this.left.map((type) => {
      if (type === 'BP') {
        types.push('BPH', 'BPL');
      } else {
        types.push(type);
      }
    });
    this.right.map((type) => {
      if (type === 'BP') {
        types.push('BPH', 'BPL');
      } else {
        types.push(type);
      }
    });
    if (!this.patientId || types.length === 0) {
      return [];
    }
    // @todo APIをモックから差し替える
    // @todo APIの返り値に応じて処理を修正する
    const res = await this.vitalService.getVitalList(this.patientId, this.start, this.end, types);
    const latestDataList: LatestDataList = {};
    const series = [];
    // 体温データ
    if (res.hasOwnProperty('BT')) {
      const seriesBT = this.getSeriesBT(res);
      series.push(seriesBT);
      // 最新の値
      latestDataList.BT = this.getLatestData(seriesBT.data);
    }
    // 血圧データ
    if (res.hasOwnProperty('BPH') && res.hasOwnProperty('BPL')) {
      const seriesBP = this.getSeriesBP(res);
      series.push(...seriesBP);
      // 最新の値
      latestDataList.BPH = this.getLatestData(seriesBP[0].data);
      latestDataList.BPL = this.getLatestData(seriesBP[1].data);
    }
    // 脈拍データ
    if (res.hasOwnProperty('PR')) {
      const seriesPR = this.getSeriesPR(res);
      series.push(seriesPR);
      // 最新の値
      latestDataList.PR = this.getLatestData(seriesPR.data);
    }
    // SpO2データ
    if (res.hasOwnProperty('SpO2')) {
      const seriesSpO2 = this.getSeriesSpO2(res);
      series.push(seriesSpO2);
      // 最新の値
      latestDataList.SpO2 = this.getLatestData(seriesSpO2.data);
    }
    // 呼吸数データ
    if (res.hasOwnProperty('RR')) {
      const seriesRR = this.getSeriesRR(res);
      series.push(seriesRR);
      // 最新の値
      latestDataList.RR = this.getLatestData(seriesRR.data);
    }
    // 血糖値データ
    if (res.hasOwnProperty('BS')) {
      const seriesBS = this.getSeriesBS(res);
      series.push(seriesBS);
      // 最新の値
      latestDataList.BS = this.getLatestData(seriesBS.data);
    }
    // 身長データ
    if (res.hasOwnProperty('BH')) {
      const seriesBH = this.getSeriesBH(res);
      series.push(seriesBH);
      // 最新の値
      latestDataList.BH = this.getLatestData(seriesBH.data);
    }
    // 体重データ
    if (res.hasOwnProperty('BW')) {
      const seriesBW = this.getSeriesBW(res);
      series.push(seriesBW);
      // 最新の値
      latestDataList.BW = this.getLatestData(seriesBW.data);
    }
    // 外部コンポーネントに最新の値を通知
    this.updateLatestData.emit(latestDataList);

    return series;
  }

  // 体温の返り値を表示用に整形する
  getSeriesBT(res) {
    let dataVitalBT = res.BT.map((d) => {
      // タイムスタンプがミリ秒単位でなければ1000倍しておく
      const time = d.time > 10000000000 ? d.time : d.time * 1000;
      // [タイムスタンプ, 値]の配列で1レコード
      return [time, Number(d.value)];
    });
    return {
      data: dataVitalBT,
      tooltip: this.optionByVitalType.BT.tooltip,
      yAxis: 'BT',
      id: 'seriesBT',
    };
  }
  // 血圧の返り値を表示用に整形する
  getSeriesBP(res) {
    // 高い方
    let dataVitalBPH = res.BPH.map((d) => {
      const time = d.time > 10000000000 ? d.time : d.time * 1000;
      return [time, Number(d.value)];
    });
    // 低い方
    let dataVitalBPL = res.BPL.map((d) => {
      const time = d.time > 10000000000 ? d.time : d.time * 1000;
      return [time, Number(d.value)];
    });
    return [
      {
        data: dataVitalBPH,
        color: '#99f',
        tooltip: this.optionByVitalType.BP.tooltip,
        yAxis: 'BP',
        id: 'seriesBPH',
      },
      {
        data: dataVitalBPL,
        color: '#f99',
        tooltip: this.optionByVitalType.BP.tooltip,
        yAxis: 'BP',
        id: 'seriesBPL',
      },
    ];
  }
  // 脈の返り値を表示用に整形する
  getSeriesPR(res) {
    let dataVitalPR = res.PR.map((d) => {
      const time = d.time > 10000000000 ? d.time : d.time * 1000;
      return [time, Number(d.value)];
    });
    return {
      data: dataVitalPR,
      tooltip: this.optionByVitalType.PR.tooltip,
      yAxis: 'PR',
      id: 'seriesPR',
    };
  }
  // SpO2の返り値を表示用に整形する
  getSeriesSpO2(res) {
    let data = res.SpO2.map((d) => {
      const time = d.time > 10000000000 ? d.time : d.time * 1000;
      return [time, Number(d.value)];
    });
    return {
      data: data,
      tooltip: this.optionByVitalType.SpO2.tooltip,
      yAxis: 'SpO2',
      id: 'seriesSpO2',
    };
  }
  // 呼吸数の返り値を表示用に整形する
  getSeriesRR(res) {
    let data = res.RR.map((d) => {
      const time = d.time > 10000000000 ? d.time : d.time * 1000;
      return [time, Number(d.value)];
    });
    return {
      data: data,
      tooltip: this.optionByVitalType.RR.tooltip,
      yAxis: 'RR',
      id: 'seriesRR',
    };
  }
  // 血糖値の返り値を表示用に整形する
  getSeriesBS(res) {
    let data = res.BS.map((d) => {
      const time = d.time > 10000000000 ? d.time : d.time * 1000;
      return [time, Number(d.value)];
    });
    return {
      data: data,
      tooltip: this.optionByVitalType.BS.tooltip,
      yAxis: 'BS',
      id: 'seriesBS',
    };
  }
  // 身長の返り値を表示用に整形する
  getSeriesBH(res) {
    let data = res.BH.map((d) => {
      const time = d.time > 10000000000 ? d.time : d.time * 1000;
      return [time, Number(d.value)];
    });
    return {
      data: data,
      tooltip: this.optionByVitalType.BH.tooltip,
      yAxis: 'BH',
      id: 'seriesBH',
    };
  }
  // 体重の返り値を表示用に整形する
  getSeriesBW(res) {
    let data = res.BW.map((d) => {
      const time = d.time > 10000000000 ? d.time : d.time * 1000;
      return [time, Number(d.value)];
    });
    return {
      data: data,
      tooltip: this.optionByVitalType.BW.tooltip,
      yAxis: 'BW',
      id: 'seriesBW',
    };
  }

  getLatestData(dataList: [number, number][]) {
    let latest = null;
    if (dataList.length > 0) {
      latest = dataList.reduce((result, data) => {
        if (result[0] < data[0]) {
          result = data;
        }
        return result;
      });
    }
    // ミリ秒から戻しておく
    if (latest) {
      latest = {
        time: Math.floor(latest[0] / 1000),
        value: latest[1],
      }
    }
    return latest;
  }
  // 外部にデータ一覧を返す
  public exportData() {
    const result: { [vitalType: string]: any } = {};
    // 両軸
    const Axis = ['left', 'right'];
    Axis.map((A) => {
      this[A].map((type) => {
        let dataObjectList;
        if (type === 'BP') {
          // get('id')でseriesを取得する。型アサーションをなくすとdataがないと怒られる
          const optBPL = this._chart.get('seriesBPL').options as Highcharts.SeriesLineOptions;
          const optBPH = this._chart.get('seriesBPH').options as Highcharts.SeriesLineOptions;
          result.BPL = optBPL.data;
          result.BPH = optBPH.data;
        } else {
          const opt = this._chart.get('series' + type).options as Highcharts.SeriesLineOptions;
          result[type] = opt.data;
        }
      });
    })
    return result;
  }

}

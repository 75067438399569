import { Component, OnInit, Input } from '@angular/core';
import { TagType } from '@srcapp/types/vital.type';
import * as moment from 'moment';

export type Comment = {
  comment: string;
  time: number;
}

export type Tag = {
  tagList: TagType[];
  time: number;
}

@Component({
  selector: 'app-comment-list-popover',
  templateUrl: './comment-list-popover.component.html',
  styleUrls: ['./comment-list-popover.component.scss'],
})
export class CommentListPopoverComponent implements OnInit {
  @Input() patientName: string = '';
  @Input() commentList: Comment[] = [];
  @Input() tagList: Tag[] = [];

  // 同一時間は１つのレコードで管理
  commentTagList: {
    time: number,
    tagList: Tag[],
    commentList: Comment[]
  }[] = [];

  constructor() { }

  ngOnInit() {
    const margeList = [...this.commentList, ...this.tagList].sort((a, b) => {
      return a.time - b.time;
    });
    console.log(margeList);

    let currentTime = 0;
    let _tmpCommentTag: {
      time: number,
      tagList: Tag[],
      commentList: Comment[]
    } = null;

    // 同一時間は同じ時間として表示する
    margeList.forEach((data: any) => {
      if (currentTime === data.time) {
        if (_tmpCommentTag) {
          if (data.tagList) _tmpCommentTag.tagList.push(...data.tagList);
          if (data.comment) _tmpCommentTag.commentList.push(data.comment);
        } else {
          _tmpCommentTag = {
            time: data.time,
            tagList: (data.tagList) ? data.tagList : [],
            commentList: (data.comment) ? [data.comment] : [],
          }
        }
      } else {
        currentTime = data.time;
        if (_tmpCommentTag) this.commentTagList.push(_tmpCommentTag);
        _tmpCommentTag = {
          time: data.time,
          tagList: (data.tagList) ? data.tagList : [],
          commentList: (data.comment) ? [data.comment] : [],
        }
      }
    })
    if (_tmpCommentTag) this.commentTagList.push(_tmpCommentTag);

    console.log(this.commentTagList);
  }

}

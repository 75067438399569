import { AbstractDevice, DEVICE_TYPES } from './abstractDevice.model';
import { GATTService } from '@srcapp/services/ble/gatt.service';
import { BleConnectDeviceJsonType } from '@srcapp/types/bleDeviceModel.typs'
import { VITAL_TYPES } from '@srcapp/config/vital.config';
import { VitalDataType, VitalDataDeviceType } from '@srcapp/types/vital.type';
import * as moment from 'moment';

export class AanddUA651BLEDevice extends AbstractDevice {
  // クラス毎に設定する情報
  makerCode = 'A&D';
  makerName = 'A&D';
  productCode = 'UA651BLE';
  productName = 'UA-651BLE';
  deviceType = DEVICE_TYPES.SPH;
  deviceTypeName = '血圧計';
  deviceImage = './assets/imgs/devices/A&D/A&D_UA-651BLE.png';

  // A&Dのサービス情報を定義する
  characteristic = {
    dateTime: {
      service: '1810', // dateTimeServiceUUID
      // org.bluetooth.characteristic.date_time	
      characteristic: '2A08', // dateTimeCharacteristicUUID
    },
    // 全削除するためのService
    customAllDelete: {
      service: '233BF000-5A34-1B6D-975C-000D5690ABE4',
      characteristic: '233BF001-5A34-1B6D-975C-000D5690ABE4'      
    },

    // 血圧を取得するイベント
    getData: {
      service: '1810',
      characteristic: '2A35',
    },
    // 全削除するためのService
    customInfo: {
      service: '233BF000-5A34-1B6D-975C-000D5690ABE4',
      characteristic: '233BF001-5A34-1B6D-975C-000D5690ABE4',
      getAllData: [0x02, 0x00, 0xE1],
      deleteAllData:[0x03, 0x01, 0xa6, 0x00],
    },
  }


  /**
   * ペアリングの処理を司る
   */
  async paringInitData(): Promise<boolean> {
    if (this.isDebug) {
      return true;
    }
    const dateTimeConf = this.characteristic.dateTime;
    const buffer = GATTService.getBinaryDateTime();
    console.log('1-1. start BLE write');
    return this.ble.write(this.deviceId, dateTimeConf.service, dateTimeConf.characteristic, buffer).then(() => {
      console.log('1-2. end BLE write');
      // 設定完了後、設定した時間が正しいかを取得する
      console.log('2-1. start BLE read');
      return this.ble.read(this.deviceId, dateTimeConf.service, dateTimeConf.characteristic);
    }).then((resDatetime) => {
      console.log('2-2. end BLE read');
      const timestamp = GATTService.readBinaryTimestamp(resDatetime);
      console.log(timestamp);
      // 計測機器のデータを全て削除する処理
      const costomInfo = this.characteristic.customInfo;
          
      const buffer2 = new ArrayBuffer(4);
      const dv = new DataView(buffer2);
      costomInfo.deleteAllData.forEach((value, index) => dv.setUint8(index, value));
      console.log('3-1. start BLE cosutom write');
      return this.ble.write(this.deviceId, costomInfo.service, costomInfo.characteristic, dv.buffer);
    }).then(result => {
      console.log('3-2. end BLE cosutom write');
      console.log(result);
      console.log('4-1. start BLE disconnect');
      return this.ble.disconnect(this.deviceId);
    }).then(() => {
      console.log('4-2. end BLE disconnect');
      return true;
    }).catch(e => {
      console.error('接続中のエラー');
      console.error(e);
      return false;
    });
  }

  notificationList: any[] = [];

  async getData(): Promise<VitalDataType[] > {
    if (this.isDebug) {
      return [
        {
          vitalType: VITAL_TYPES.BODY_HEIGHT,
          vitalName: '血圧(上)',
          value: '77',
          unit: '',
          time: moment().unix(),
          device: this.getDeviceInfo()
        },
        {
          vitalType: VITAL_TYPES.BODY_WEIGHT,
          vitalName: '血圧(下)',
          value: '57',
          unit: '',
          time: moment().unix(),
          device: this.getDeviceInfo()
        },
        {
          vitalType: VITAL_TYPES.PLUSE,
          vitalName: '脈拍',
          value: '72',
          unit: '',
          time: moment().unix(),
          device: this.getDeviceInfo()
        }
      ];
    }
    this.notificationList = [];

    console.log('[Worker] 1:_connect デバイスに接続する');
    console.log('接続先ID:' + this.deviceId);

    this.ble.connect(this.deviceId).subscribe(async () => {
      console.log('connect OK:' + this.deviceId);
      await this.sleep(200);

      const customInfo = this.characteristic.customInfo;
      var buffer1 = new ArrayBuffer(20);
      var dv = new DataView(buffer1);
      customInfo.getAllData.forEach((value, index) => dv.setUint8(index, value));
      this.ble.write(this.deviceId, customInfo.service, customInfo.characteristic, dv.buffer).then((res) => {
        console.log('全データ取得 命令の実行');
        console.log(res);
        console.log('[Worker] 2:startNotification 受信待ちの処理');
        const notificationInfo = this.characteristic.getData;
        this.ble.startNotification(this.deviceId, notificationInfo.service, notificationInfo.characteristic).subscribe(res => {
          console.log('[BleDeviceService] INFO: *** ！！！バイナリデータ受信中！！！ ***');
          console.log(res);
          console.log('[BleDeviceService] INFO: *** 解析結果 ***');
          const vitals = this.getBloodPressureValue(res);
          console.log(vitals);
          if (vitals) {
            console.log(this.notificationList.length);
            this.notificationList.push(vitals);  
          }
        }, (e) => {
          console.log('[ERROR] 受信待ち');
          console.log(e);
        });


      }).catch((e) => {
        console.log('[ERROR] ble.write: ' + this.deviceId);
        console.log(e);
      });
    }, (e) => {
      console.log('[ERROR] connect: ' + this.deviceId);
      console.log(e);
    });

    return new Promise((resolve) => {
      setTimeout(async () => {
        const notificationInfo = this.characteristic.getData;
        await this.ble.stopNotification(this.deviceId, notificationInfo.service, notificationInfo.characteristic).then((res) => {
          console.log('***disconnect:then');
          console.log(res);
          return this.ble.disconnect(this.deviceId);
        }).catch((error) => {
          console.log('***stopNotification:catch');
          console.log(error);
          return this.ble.disconnect(this.deviceId);
        });
        let vitals = this.notificationList.pop();
        return resolve(vitals);
      }, 5000);
    });
  }



  getBloodPressureValue(res): VitalDataType[] {
    let offset = 1;

    let BPH = 0; // 血圧上
    let BPL = 0; // 血圧下
    let PR = 0;  // 脈拍
    if (res && Array.isArray(res)) {
      res = res[0];
    }
    let dv = new DataView(res);

    console.log('データの内容を見る');
    for (var i =0; i < dv.byteLength; i++) {
      console.log(dv.getUint8(i).toString(16));
    }

    // 1byte目はフラグ情報
    // - 0: 単位 0:mmhg(C1) or 1:kPa(C2)	C1
    // - 1: Timestamp 0: 無し、1:あり(C3)	なし
    // - 2: 脈拍データ 0: 無し、1:あり(C4)	C4
    // - 3: ユーザIDデータ 0: 無し、1:あり(C5)	なし
    // - 4: 計測ステータス 0: 無し、1:あり(C6)	C6
    // 1byte目
    let byte1 = dv.getUint8(0);
    var littleEndian = true;

    // 単位を取得: 0 => mmhg(C1), 1 => kPa(C2)
    if ((byte1 & 0x01) === 0) {
      // C1方式の場合は、2byte目~7byte目までに2byteずつデータが入っている
      var buffer = new ArrayBuffer(16);
      var data = new DataView(buffer);
      data.setUint16(0, dv.getUint16(offset));
      BPH = GATTService.readSFLOAT(data, littleEndian);
      console.log('血圧(上)');
      console.log(BPH);
      offset += 2;

      var buffer2 = new ArrayBuffer(16);
      var data2 = new DataView(buffer2);
      data2.setUint16(0, dv.getUint16(offset));
      BPL = GATTService.readSFLOAT(data2, littleEndian);
      console.log('血圧(下)');
      console.log(BPL);
      offset += 2;

      var buffer3 = new ArrayBuffer(16);
      var data3 = new DataView(buffer3);
      data3.setUint16(0, dv.getUint16(offset));
      let result = GATTService.readSFLOAT(data3, littleEndian);
      console.log('平均');
      console.log(result);
      offset += 2;

    } else {
      // kPa(C2)は扱わない
    }
      
    // 時間を取得
    var measuredTime = null;
    // 2bit: 0: NULL, 1: Time Stamp field present(C3)
    if ((byte1 & 0x02) === 0) {
      // 時間がないのでパス
      console.log('時間情報なし');
    } else {
      measuredTime = GATTService.readDateTime(dv, offset, littleEndian);
      offset += 7;
    }

    // 脈拍データを取得
    // 3bit: 0: NULL, 1: Pulse Rate present (C4) (fixed)
    if ((byte1 & 0x03) === 0) {
      console.log('脈拍データ無し');
    } else {
      var buffer4 = new ArrayBuffer(16);
      var data4 = new DataView(buffer4);
      data4.setUint16(0, dv.getUint16(offset));
      PR = GATTService.readSFLOAT(data4, littleEndian);
      console.log('脈拍データ');
      console.log(PR);
      offset += 2;
    }

    // A&D側でエラーが発生した場合に 2047 などよくわからない数字が渡ってくることがある
    // そのため、ここでは上限値を超えているデータについては握りつぶすことにする
    if (BPH < 0 || 320 < BPH) {
      console.log('[BleDeviceService] WARNING: *** エラーの値を取得したため処理としては握りつぶす ***');
      console.log(BPH);
      return null;
    }
    if (BPL < 0 || 320 < BPL) {
      console.log('[BleDeviceService] WARNING: *** エラーの値を取得したため処理としては握りつぶす ***');
      console.log(BPL);
      return null;
    }

    const vitals = [];
    if (BPH) {
      vitals.push({
        vitalType: VITAL_TYPES.BLOOD_PRESSURE_UP,
        vitalName: '血圧(上)',
        value: `${(BPH).toFixed(0)}`,
        unit: '',
        time: measuredTime,
        device: this.getDeviceInfo()
      });  
    }
    if (BPL) {
      vitals.push({
        vitalType: VITAL_TYPES.BLOOD_PRESSURE_DOWN,
        vitalName: '血圧(下)',
        value: `${(BPL).toFixed(0)}`,
        unit: '',
        time: measuredTime,
        device: this.getDeviceInfo()
      }); 
    }

    if (PR) {
      vitals.push({
        vitalType: VITAL_TYPES.PLUSE,
        vitalName: '脈拍',
        value: `${(PR).toFixed(0)}`,
        unit: '',
        time: measuredTime,
        device: this.getDeviceInfo()
      });
    }

    console.log('計測バイタルレスポンス');
    console.log(vitals);
    return vitals;
  }

}

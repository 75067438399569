import { AbstractDevice, DEVICE_TYPES } from './abstractDevice.model';
import { GATTService } from '@srcapp/services/ble/gatt.service';
import { BleConnectDeviceJsonType } from '@srcapp/types/bleDeviceModel.typs'
import { VITAL_TYPES } from '@srcapp/config/vital.config';
import { VitalDataType } from '@srcapp/types/vital.type';
import * as moment from 'moment';
import { take } from 'rxjs/operators';


export class AanddUT201BLEDevice extends AbstractDevice {
  // クラス毎に設定する情報
  makerCode = 'A&D';
  makerName = 'A&D';
  productCode = 'UT201BLE';
  productName = 'UT201BLE';
  deviceType = DEVICE_TYPES.THE;
  deviceTypeName = '体温計';
  deviceImage = './assets/imgs/devices/A&D/A&D_UT201BLE.png';

  // A&Dのサービス情報を定義する
  characteristic = {
    dateTime: {
      service: '1809', // dateTimeServiceUUID
      // org.bluetooth.characteristic.date_time	
      characteristic: '2A08', // dateTimeCharacteristicUUID
    },
    // 体温を取得するイベント
    getData: {
      service: '1809',
      characteristic: '2A1C',
    },
    // 全削除するためのService
    customInfo: {
      service: '233BF000-5A34-1B6D-975C-000D5690ABE4',
      characteristic: '233BF001-5A34-1B6D-975C-000D5690ABE4',
      getAllData: [0x02, 0x00, 0xE1],
      deleteAllData:[0x03, 0x01, 0xa6, 0x00],
    },

  }

  /**
   * ペアリングの処理を司る
   */
  async paringInitData(): Promise<boolean> {
    if (this.isDebug) {
      return true;
    }
    const dateTimeConf = this.characteristic.dateTime;
    const buffer = GATTService.getBinaryDateTime();
    console.log('1-1. start BLE write');
    return this.ble.write(this.deviceId, dateTimeConf.service, dateTimeConf.characteristic, buffer).then(() => {
      console.log('1-2. end BLE write');
      // 設定完了後、設定した時間が正しいかを取得する
      console.log('2-1. start BLE read');
      return this.ble.read(this.deviceId, dateTimeConf.service, dateTimeConf.characteristic);
    }).then((resDatetime) => {
      console.log('2-2. end BLE read');
      const timestamp = GATTService.readBinaryTimestamp(resDatetime);
      console.log(timestamp);
      // 計測機器のデータを全て削除する処理
      const costomInfo = this.characteristic.customInfo;
          
      const buffer2 = new ArrayBuffer(4);
      const dv = new DataView(buffer2);
      costomInfo.deleteAllData.forEach((value, index) => dv.setUint8(index, value));
      console.log('3-1. start BLE cosutom write');
      return this.ble.write(this.deviceId, costomInfo.service, costomInfo.characteristic, dv.buffer);
    }).then(result => {
      console.log('3-2. end BLE cosutom write');
      console.log(result);
      console.log('4-1. start BLE disconnect');
      return this.ble.disconnect(this.deviceId);
    }).then(() => {
      console.log('4-2. end BLE disconnect');
      return true;
    }).catch(e => {
      console.error('接続中のエラー');
      console.error(e);
      return false;
    });
  }

  notificationList: any[] = [];

  async getData(): Promise<VitalDataType[] > {
    if (this.isDebug) {
      return [
        {
          vitalType: VITAL_TYPES.BODY_TEMPERATURE,
          vitalName: '体温',
          value: '36.5',
          unit: '',
          time: moment().unix(),
          device: this.getDeviceInfo()
        }
      ]
    }
    this.notificationList = [];

    console.log('[Worker] 1:_connect デバイスに接続する');
    console.log('接続先ID:' + this.deviceId);

    this.ble.connect(this.deviceId).subscribe(async () => {
      console.log('connect OK:' + this.deviceId);
      await this.sleep(200);

      const customInfo = this.characteristic.customInfo;
      var buffer1 = new ArrayBuffer(20);
      var dv = new DataView(buffer1);
      customInfo.getAllData.forEach((value, index) => dv.setUint8(index, value));
      this.ble.write(this.deviceId, customInfo.service, customInfo.characteristic, dv.buffer).then((res) => {
        console.log('全データ取得 命令の実行');
        console.log(res);
        console.log('[Worker] 2:startNotification 受信待ちの処理');

        const notificationInfo = this.characteristic.getData;
        this.ble.startNotification(this.deviceId, notificationInfo.service, notificationInfo.characteristic).subscribe(res => {
          console.log('[BleDeviceService] INFO: *** ！！！バイナリデータ受信中！！！ ***');
          console.log(res);
          console.log('[BleDeviceService] INFO: *** 解析結果 ***');
          const vitals = this.getBodyMeasurementValue(res);
          console.log(vitals);
          if (vitals) {
            console.log(this.notificationList.length);
            this.notificationList.push(vitals);  
          }
        }, (e) => {
          console.log('[ERROR] 受信待ち');
          console.log(e);
        });


      }).catch((e) => {
        console.log('[ERROR] ble.write: ' + this.deviceId);
        console.log(e);
      });
    }, (e) => {
      console.log('[ERROR] connect: ' + this.deviceId);
      console.log(e);
    });

    return new Promise((resolve) => {
      setTimeout(async () => {
        const notificationInfo = this.characteristic.getData;
        await this.ble.stopNotification(this.deviceId, notificationInfo.service, notificationInfo.characteristic).then((res) => {
          console.log('***disconnect:then');
          console.log(res);
          return this.ble.disconnect(this.deviceId);
        }).catch((error) => {
          console.log('***stopNotification:catch');
          console.log(error);
          return this.ble.disconnect(this.deviceId);
        });
        let vitals = this.notificationList.pop();
        return resolve(vitals);
      }, 5000);
    });
  }

  getBodyMeasurementValue(res): VitalDataType[] {
    let offset = 1;
    var result = 0;
    if (res && Array.isArray(res)) {
      res = res[0];
    }
    let dv = new DataView(res);

    // 1byte目
    let byte1 = dv.getUint8(0);
    var littleEndian = true;

    // C1方式かC2方式化を選択する
    // 0bit: 0=Celsius (C1), 1=Fahrenheit (C2)
    if ((byte1 & 0x01) === 0) {
      // C1方式　2byte目~5byte目
      var buffer = new ArrayBuffer(32);
      var data = new DataView(buffer);
      data.setUint32(0, dv.getUint32(offset));

      result = GATTService.readFLOAT(data, littleEndian);
      console.log(result);

      // 4byte読み込んだので+4
      offset += 4;
    } else {
      // C2方式　2byte目~5byte目
      console.log('C2方式では読み込めません')
    }

    var measuredTime = null;

    // 時間情報があるかないか
    // 1bit: 0: NULL, 1: Time Stamp field present(C3)
    if ((byte1 & 0x02) === 0) {
      // 時間がないのでパス
      console.log('時間情報なし');
    } else {
      // 時間設定を 6byte目~12byte目までが時間情報
      measuredTime = GATTService.readDateTime(dv, offset, littleEndian);
      offset += 7;
    }

    // 計測状態を取得する
    // 2bit: 0: NULL, 1: Type field present (C4) (fixed)
    if ((byte1 & 0x03) === 0) {
      // 終了
    } else {
      // 計測状態を取得する
    }


    // A&D側でエラーが発生した場合に8388607などよくわからない数字が渡ってくることがある
    // そのため、ここでは上限値を超えているデータについては握りつぶすことにする
    if (result < 30 || 50 < result) {
      console.log('[BleDeviceService] WARNING: *** エラーの値を取得したため処理としては握りつぶす ***');
      console.log(result);
      // TODO: rejectしても良いかも！？ 
      return null;
    }

    const vitals = [];
    vitals.push({
      vitalType: VITAL_TYPES.BODY_TEMPERATURE,
      vitalName: '体温',
      value: `${(result).toFixed(1)}`,
      unit: '',
      time: measuredTime,
      device: this.getDeviceInfo()
    });
    return vitals;
  }

}

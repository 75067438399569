import { AbstractPage } from './../../../pages/abstract-page';
import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { MASTER_DEVICE_COLOR_LABEL } from '../../../config/label.config';
import { DeviceService } from '../../../services/device.service';
import { BleService } from '../../../services/ble/ble.service';
import { BleScanDeviceJsonType, BleDeviceModelType } from '../../../types/bleDeviceModel.typs';
import { takeUntil, take } from 'rxjs/operators';
import { BLE } from '@ionic-native/ble/ngx';


enum STEP_TYPES {
  /**
   * STEP1: 端末をペアリングモードにする
   */
  STEP_INIT = 'step.init',

  /**
   * STEP3: 端末とのペアリング
   */
  SETE_DISCOVER = 'step.discover',
  /**
   * STEP4: 端末の編集
   */
  STEP_EDIT = 'step.edit',
}

const STEP_TYPE_INFO = {
  [STEP_TYPES.STEP_INIT]: {title: '端末の準備'},
  [STEP_TYPES.SETE_DISCOVER]: {title: 'ペアリングの実施'},
  [STEP_TYPES.STEP_EDIT]: {title: 'デバイス情報の登録'},
}



@Component({
  selector: 'app-ble-paring-modal',
  templateUrl: './ble-paring-modal.page.html',
  styleUrls: ['./ble-paring-modal.page.scss'],
})
export class BleParingModalPage extends AbstractPage implements OnInit, OnDestroy {

  // Data passed in by componentProps
  @Input() device: BleDeviceModelType;
  @Input() mode: 'init' | 'edit';

  /**
   * ペアリング画面遷移のステータス管理
   */
  STEP_TYPES = STEP_TYPES;
  /**
   * デバイスのラベル情報の管理
   */
  deviceColorLavel = MASTER_DEVICE_COLOR_LABEL;

  /**
   * 現在のステータス
   */
  currentStep:string = STEP_TYPES.STEP_INIT;
  /**
   * 現在のステータスに合わせたヘッダータイトル
   */
  pageHeaderTitle:string = STEP_TYPE_INFO[this.currentStep].title;

  // *** 入力データ ***
  inputDeviceName: string = '';
  selectedLabel = MASTER_DEVICE_COLOR_LABEL[0];
  /**
   * ペアリング対象の機器情報
   */
  deviceModel: BleDeviceModelType;

  isScaning = false;
  isNotFound = false;
  timerId;


  constructor(
    public modalController: ModalController,
    private alertController: AlertController,
    private ble: BLE,
    private deviceService: DeviceService,
    private loadingController: LoadingController,
    private bleService: BleService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }


  ngOnInit() {
    // 引数でデバイスが渡ってきた場合は、編集のためSCANは実施しない
    if (this.device) {
      this.deviceModel = this.device;
      this.inputDeviceName = this.deviceModel.name;
      this.selectedLabel = this.deviceModel.labelInfo;
      this.currentStep = STEP_TYPES.STEP_EDIT;
    }
  }

  async ngOnDestroy() {
    await this.ble.stopScan().then(() => console.log('stop scan')).catch((e) => console.log('stop scan error', e));
    super.ngOnDestroy();
  }

  async closeModel() {
    this.modalController.dismiss({ reflect: false });
  }


  /**
   * BLEのデバイスを検索し、接続まで実施
   */
  async searchDevice() {
    if (this.isScaning) return;

    if (this.loading) await this.loading.dismiss();

    this.loading = await this.loadingController.create({
      message: '端末を検索中...'
    })
    this.loading.present();

    this.deviceModel = null;
    this.isNotFound = false;

    const isEnabled = this.ble.isEnabled().then(() => true).catch(() => false);
    if (!isEnabled) {
      if (this.loading) {
        await this.loading.dismiss();
        this.loading = null;
        this.changeDetectorRef.detectChanges();
      }
      const alert = await this.alertController.create({
        header: 'Bluetoothの利用ができません',
        message: '端末の設定にて<br>Bluetoothの利用設定を<br>ONにしてください。',
        buttons: ['確認']
      });
      await alert.present();
      this.changeDetectorRef.detectChanges();
      return ;
    }

    this.isScaning = true;
    if (this.timerId) clearTimeout(this.timerId);
    this.timerId = setTimeout(async () => {
      this.ble.stopScan();
      if (this.loading) {
        await this.loading.dismiss();
        this.loading = null;
        this.changeDetectorRef.detectChanges();
      }
      this.isScaning = false;
      if (!this.deviceModel) {
        this.isNotFound = true;
      }
      // 接続
    }, 1000*20);

    // A&Dの体温計と血圧計の接続
    // const services = ['1809'];
    // const services = ['1809', '18A0']; // TODO: '1809' and '18A0'
    const services = [];
    this.ble.startScan(services).subscribe(async(device: BleScanDeviceJsonType) => {
      console.log(device.name);

      if (!device.name) return;
      if (device.name.includes( "A&D_UT201BLE")
          || device.name.includes( "A&D_UA-651BLE")
          || device.name.includes( "101B-")
          || device.name.includes( "HEM-6233T")
          || device.name.includes( "HCR-7501T")
          || device.name.includes( "BLEsmart_")
          || device.name.includes( "BLESmart_")
      ) {
      } else {
        return ;
      }
      const rawdata = this.getRAWDATA(device);
      console.log(rawdata);
      // OMRON手首式血圧計: "BLEsmart_0000004C28FFB24FDF12"
      // 上腕式血圧計: ""BLEsmart_00000068EC21E583F3D5""
      // 020106020a000302101808ff0e0201080200021e09424c45736d6172745f3030303030303638454332314535383346334435000000000000000000000000
      // A&D血圧計: A&D_UA-651BLE_005D18
      if (this.deviceModel) return ;
      const deviceModel = this.bleService.getDeviceModel(device)
      if (!deviceModel) return ;

      await this.ble.stopScan();
      if (this.timerId) clearTimeout(this.timerId);
      this.isScaning = false;


      console.log('発見!', deviceModel)
      // 既に接続済みの端末かをチェックする
      const _device = this.deviceService.deviceList.find(d => d.deviceId === deviceModel.deviceId);
      if (_device) {
        console.log('既に管理済み端末', _device);
        this.deviceModel = _device;
        this.inputDeviceName =_device.name;
        if (_device.labelInfo) {
          this.selectedLabel = _device.labelInfo;
        }

      } else {
        this.deviceModel = deviceModel;
      }

      // AndroidにてstopScanをするとその後接続できない、問題があった...
      // await this.ble.stopScan();

      // Androidにてpipe(take(1)) とするとなぜかエラーになる。またtoPromiseにしてもエラーでBLEに接続できない問題があった
      // OMRON機器の場合は、ライブラリの中でコネクトもするため、ここでわざわざ接続する必要はなさそう（A&Dのみ接続対象とする）
      // ただし、OMRONの中でも非接触の体温計（TM101B02）は特殊なBLEインターフェースであるため、A&Dと同じにしておく
      if (this.deviceModel.makerCode === 'A&D' || this.deviceModel.productCode === 'TM101B02') {
        console.log('connect to =', device.id);
        this.ble.connect(device.id).subscribe(async (cDevice) => {
          console.log('connect: OK', device.id);
          console.log(cDevice);
          if (this.timerId) clearTimeout(this.timerId);
          if (this.loading) {
            await this.loading.dismiss();
            this.loading = null;
          }
          // 既に発見済みであれば何もしない
          if ( this.currentStep === STEP_TYPES.STEP_EDIT) return;
          this.currentStep = STEP_TYPES.SETE_DISCOVER
          this.changeDetectorRef.detectChanges();

        }, async (e) => {
          console.log('connect: ERROR', device.id);
          console.log(e);
          this.isScaning = false;
          // 既に発見済みであれば何もしない
          if (this.currentStep === STEP_TYPES.STEP_EDIT) return;
          if (this.loading) {
            await this.loading.dismiss();
            this.loading = null;
          }
          this.errorModal();
          return null;
        });
      } else if (this.deviceModel.makerCode === 'OMRON') {
          if (this.timerId) clearTimeout(this.timerId);
          if (this.loading) {
            await this.loading.dismiss();
            this.loading = null;
          }
          // 既に発見済みであれば何もしない
          if ( this.currentStep === STEP_TYPES.STEP_EDIT) return;
          this.currentStep = STEP_TYPES.SETE_DISCOVER
          this.changeDetectorRef.detectChanges();
      }
    });

  }

  getRAWDATA(bleDevice): string {
    // Androidの場合のみadvertisingにArratBufferが入って生データを取得できる
    // iOSは別の方法で取得する必要がある...
    //  - https://ionicframework.com/docs/native/ble#ios
    let data: ArrayBuffer = bleDevice.advertising;

    if (!data) {
      return '';
    }
    try {
      const dv = new DataView(data);
      const list = [];
      for (let i = 0; i < dv.byteLength; i++) {
        list.push(('00' + dv.getUint8(i).toString(16)).slice(-2));
      }
      return list.join('');
    } catch (e) {
      console.log(e);
    }
    return '';
  }

  /**
   * ペアリング完了後端末の初期設定を
   */
  loading;
  async initDevice() {
    if (!this.deviceModel) return this.errorModal();

    if (this.loading) await this.loading.dismiss();

    this.loading = await this.loadingController.create({
      message: '端末の初期設定中...'
    })
    this.loading.present();

    const result = await this.deviceModel.paringInitData().catch((e) => {
                    console.log(e);
                    return false;
                  });
    console.log('接続結果:', result);
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }

    if (!result) return this.errorModal();

    this.currentStep = STEP_TYPES.STEP_EDIT;
    this.changeDetectorRef.detectChanges();
  }

  async errorModal() {
    const alert = await this.alertController.create({
      header: '計測機器への接続失敗',
      message: '接続に失敗しました。<br>お手数をおかけしますが、<br>最初からやり直してください。<br><br>うまくいかない場合は<br>下記もお試しください、<br><br>・ペアリング済みの機器を解除する<br>・Bluetoothを一度OFF、その後ONにする',
      buttons: ['確認']
    });
    await alert.present();
    this.changeDetectorRef.detectChanges();
    this.closeModel();
  }


  async save() {
    if (!this.inputDeviceName) {
      alert('ラベル名を入力してください');
      return;
    }
    console.log(this.deviceModel);
    console.log(this.inputDeviceName);
    console.log(this.selectedLabel);
    this.deviceModel.name = this.inputDeviceName;
    this.deviceModel.labelInfo = this.selectedLabel;
    await this.deviceService.seveDevice(this.deviceModel);
    this.closeModel();
  }

  /**
   * 接続されている端末にペアリングを実施する
   */
  // async completeBleParring() {
  //   this.isShowParingBtn = false;
  //   this.isShowWaitingParing = true;

  //   this.discoverDevice.paring().then(async (result) => {
  //     console.log('ペアリング完了');
  //     console.log(result);
  //     if (result) {

  //       // 初期化モードの場合は、再度ペアリング処理を実施してデータを削除する
  //       if (this.mode === 'init') {
  //         // 画面描画用にステータスや入力データのセット
  //         this.currentStep = STEP_TYPES.INITCOMP;
  //       } else {
  //         // 画面描画用にステータスや入力データのセット
  //         this.currentStep = STEP_TYPES.DISCOVER;
  //         this.inputDeviceName = this.discoverDevice.name;
  //         const label = MASTER_DEVICE_COLOR_LABEL.find(label => label.id === this.discoverDevice.labelId);
  //         if (label) {
  //           this.selectedLabel = label;
  //         }
  //       }
  //       // 強制的に再描画させる
  //       this.changeDetectorRef.detectChanges();
  //     } else {
  //       alert('ペアリングに失敗しました。お手数ですが最初から実施してください');
  //       return this.closeModel();
  //     }
  //   });
  // }


  isSelectedLable(label) {
    if (!this.selectedLabel) return false;
    return this.selectedLabel.id === label.id;
  }
  selectLabel(label) {
    console.log(label);
    this.selectedLabel = label;
    this.changeDetectorRef.detectChanges();
  }

  debug() {
    // デバッグデータを用意
    // let device = {id: '123456789', name: 'A&D_UT201BLE_1E1E', rssi: 0, advertising: []};
    let device = {id: '123456780', name: 'HEM-6233T_1E1E', rssi: 0, advertising: []};

    this.deviceModel = this.bleService.getDeviceModel(device)
    this.deviceModel.isDebug = true;
    this.inputDeviceName = this.deviceModel.name;
    if (this.deviceModel.labelInfo) {
      this.selectedLabel = this.deviceModel.labelInfo;
    }


    this.isScaning = false;
    this.currentStep = STEP_TYPES.SETE_DISCOVER

  }

}

import { Injectable } from '@angular/core';
import { ApiAbstractService } from './api-abstract.service';
import { StaffInfoType } from '@srcapp/types/apiResponse.type';

@Injectable({
  providedIn: 'root'
})
export class ApiStaffService  extends ApiAbstractService {


  /**
   * リストを取得する処理
   */
  getList() {
    return this.get(`${this.getBaseApi()}/api/v1/staffs`);
    // const res =   {"msg":"success.","statusCode":0,"staffList":[
    //   {"staffId":"5e1b5f27ad24b2de7b76e27a","name":"デモスタッフ01","nameKana":"ﾃﾞﾓｽﾀｯﾌ01","gender":"MAN","role":"0100","staffLoginId":"demo01","wardName":""},
    //   {"staffId":"5e1c8899e896a0e84b244391","name":"デモスタッフ02","nameKana":"ﾃﾞﾓｽﾀｯﾌ02","gender":"MAN","role":"0100","staffLoginId":"demo02","wardName":""},{"staffId":"5e1c88a5e896a0e84b2443fd","name":"デモスタッフ03","nameKana":"ﾃﾞﾓｽﾀｯﾌ03","gender":"MAN","role":"0100","staffLoginId":"demo03","wardName":""}],"expired":1592155019};
    // return Promise.resolve(res);
  }

  create(staff: Partial<StaffInfoType>): Promise<any> {
    return this.post(this.getBaseApi() + '/api/v1/staff', staff);
  }

  update(staff): Promise<any> {
    return this.post(this.getBaseApi() + '/api/v1/staff/' + staff.staffId, staff);
  }

  deleteById(staffId) {
    return this.delete(this.getBaseApi() + '/api/v1/staff/' + staffId);
  }
}

import { Events } from '@srcapp/services/events.servce';
import { VitalJsonType, VitalDataType, TagType } from '@srcapp/types/vital.type';
import { VITAL_TYPES } from '@srcapp/config/vital.config';
import { PatientInfoType } from '@srcapp/types/apiResponse.type';
import { StaffInfoType } from '@srcapp/types/apiResponse.type';
import { BleDeviceModelType } from '@srcapp/types/bleDeviceModel.typs';
import * as moment from 'moment';


export class VitalModel {
  logId: string;

  private events: Events;

  public time: number;

  public order?: number = 0;

  public data: VitalJsonType = {
    logId: '',
    contractCode: '',
    staffId: '',
    staffName: '',
    patientId: '',
    patientName: '',
    icon: '',
    // nicokare?:  '',
    comment:  '',
    now: 0,
    created_at: 0,
    order: 0,
    vitals: {
      // BPH: null,
      // BPL: null
    },
    tagList: [],
  }

  /**
   * 複数人計測画面で先に計測デバイスが選択されている場合
   */
  deviceModel: BleDeviceModelType = null;

  /**
   * 複数人計測の画面でBLEの受信待ちを表すフラグ
   */
  isWaitReceiveVital: boolean = false;

  constructor(events: Events, contractCode: string) {
    this.events = events;
    this.data.contractCode = contractCode;

    this.logId = `id${new Date().getTime()}_${Math.floor(Math.random()*1000)}`;
    this.data.logId = this.logId;
    this.time = moment().unix();
    // バイタル送付APIの時間指定がnowの項目のため、ここにも定義する
    this.data.now = this.time;
  }

  /**
   * ローカルの保存データをModelにセットする
   *
   * @param json
   */
  public importJson(json: VitalJsonType): void  {
    const data = Object.assign({}, json);
    this.logId = data.logId;

    let vitals = {};
    if (data.vitals) {
      Object.keys(data.vitals).forEach(key => {
        vitals[key] = Object.assign({}, data.vitals[key]);
      });
    }
    this.data = {
      logId: data.logId,
      contractCode: data.contractCode,
      staffId: data.staffId,
      staffName: data.staffName,
      patientId: data.patientId,
      patientName: data.patientName,
      // nicokare?:  '',
      comment:  data.comment,
      now: data.now,
      created_at: data.created_at,
      order: data.order,
      vitals: vitals,
      tagList: data.tagList
    };
    if (data.vitalId) {
      this.data.vitalId = data.vitalId;
    }

  }

  /**
   * ローカルへの保存やAPIへのリクエスト用にJSONデータを出力する
   */
  public exportJson(): VitalJsonType {
    return Object.assign({}, this.data);
  }

  /**
   * スタッフ情報に変更があった場合セットする
   * @param staff
   */
  setStaff(staff: StaffInfoType = null, isAutoSave: boolean = true) {
    if (staff) {
      this.data.staffId = staff.staffId;
      this.data.staffName = staff.name;
    } else {
      this.data.staffId = null;
      this.data.staffName = null;
    }
    if (isAutoSave) {
      this.saveStorage();
    }
  }

  /**
   * 利用者情報に変更があった場合セットする
   * @param staff
   */
  setPatient(patient: PatientInfoType  | { patientId: string, name: string} = null, isAutoSave: boolean = true) {
    if (patient) {
      this.data.patientId = patient.patientId;
      this.data.patientName = patient.name;
    } else {
      this.data.patientId = null;
      this.data.patientName = null;
    }
    if (isAutoSave) {
      this.saveStorage();
    }
  }

  /**
   * コメントに変更があった場合にセットする
   * @param comment
   */
  setComment(comment: string, isAutoSave: boolean = true) {
    this.data.comment = comment;
    if (isAutoSave) {
      this.saveStorage();
    }
  }

  /**
   * バイタルデータを保存する
   */
  setVital(vitalType: VITAL_TYPES, data: VitalDataType, isAutoSave: boolean = true) {
    this.data.vitals[vitalType] = Object.assign({}, data, { time: this.time });
    if (isAutoSave) {
      this.saveStorage();
    }
  }
  hasVital(vitalType: VITAL_TYPES): boolean {
    // 特定のバイタルが登録されているか
    return Object.keys(this.data.vitals).find(key => key === vitalType) !== undefined;
  }

  setVitalList(dataList: VitalDataType[], isAutoSave: boolean = true) {
    dataList.forEach(data => {
      this.data.vitals[data.vitalType] = Object.assign({}, data);
    });
    if (isAutoSave) {
      this.saveStorage();
    }
  }
  hasVitalList() {
    // バイタルが1件以上登録されているか
    const result = Object.keys(this.data.vitals).find(key => {
      const data: VitalDataType = this.data.vitals[key];
      return (data && data.value);
    });
    return (result !== undefined);
  }

  clearVitalList(isAutoSave: boolean = true) {
    this.data.vitals = {};
    if (isAutoSave) {
      this.saveStorage();
    }
  }

  setTime(timestamp: number, isAutoSave: boolean = true) {
    this.data.now = timestamp;
    this.time = timestamp;
    Object.keys(this.data.vitals).find(key => {
      this.data.vitals[key].time = timestamp;
    });
    if (isAutoSave) {
      this.saveStorage();
    }
  }

  addTag(tag: TagType, isAutoSave: boolean = true) {
    // 重複で登録できないようにはしておく
    if (!this.data.tagList) this.data.tagList = [];

    let isUpdate = false;
    this.data.tagList = this.data.tagList.map(t => {
      if (t.tagId === tag.tagId) {
        isUpdate = true;
        return tag;
      }
      return t;
    })
    if (!isUpdate) this.data.tagList.push(tag);
    if (isAutoSave) this.saveStorage();
  }
  removeTag(tag: TagType, isAutoSave: boolean = true) {
    if (!this.data.tagList) this.data.tagList = [];
    this.data.tagList = this.data.tagList.filter(t => {
      return (t.tagId !== tag.tagId);
    });
    if (isAutoSave) this.saveStorage();
  }
  isTag(tag: TagType) {
    if (!this.data.tagList) this.data.tagList = [];
    return this.data.tagList.find(t => t.tagId === tag.tagId);
  }

  private async saveStorage() {
    this.events.publish('event.update.vital', this);
  }


  /**
   * バイタリーの場合は、コメントのみの入力を許可するため、
   * 引数でフラグを受け取るようにする
   *
   * @param options
   * @returns
   */
  getErrorMsg(options = { isOnlyComment: false }) {
    let errors = [];
    // 1. スタッフが選択されているか
    if (!this.data.staffId) {
      errors.push('スタッフ');
    }
    // 2. 利用者が選択されているか
    if (!this.data.patientId) {
      errors.push('利用者');
    }
    // 3. バイタルが1件以上選択されているかどうか
    let result = Object.keys(this.data.vitals).find(key => {
      const data: VitalDataType = this.data.vitals[key];
      return (data && data.value);
    });
    // コメントのみ許可の場合は、バイタルデータが入力されていなくてもOK
    if (!result) {
      if (!options.isOnlyComment) {
        errors.push('バイタル');
      } else if (!this.data.comment) {
        errors.push('バイタルまたはコメント');
      }
    }
    return errors.length > 0 ? `${errors.join('<br>')}<br>を登録してください。`  : '';
  }

  hasError(): boolean {
    const msg = this.getErrorMsg();
    return msg.length > 0;
  }

  /**
   * 計測されたバイタルデータが存在するかを判定する
   *
   */
  isExistVitalData(): boolean {
    const vitals = this.getVitalList();
    if (!vitals) return false;
    return vitals.length > 0 ? true: false;
  }

  /**
   * 画面描画用にバイタルのリストを取得する
   */
  getVitalList() {
    let vitals: {
      [vitalType: string]: {
        vitalName: string,
        value: string,
        value2?: string,
        unit: string,
        time: number,
      }
    } = {};
    Object.keys(this.data.vitals).find(key => {
      const data: VitalDataType = this.data.vitals[key];
      if (!data) return ;

      // 値 or 端末どちらかの情報もなければ処理しない
      if (!data.value && !data.device) return ;


      if (data.vitalType === VITAL_TYPES.BLOOD_PRESSURE_UP || data.vitalType === VITAL_TYPES.BLOOD_PRESSURE_DOWN) {
        if (!vitals['BLOOD_PRESSURE']) {
          vitals['BLOOD_PRESSURE'] = {
            // vitalName: data.vitalName,
            vitalName: '血圧',
            value: '',
            value2: '',
            unit: data.unit,
            time: data.time,
          };
        }
        if (data.vitalType === VITAL_TYPES.BLOOD_PRESSURE_UP) {
          vitals['BLOOD_PRESSURE'].value = data.value;
        } else {
          vitals['BLOOD_PRESSURE'].value2 = data.value;
        }
      } else {
        vitals[key] = {
          vitalName: data.vitalName,
          value: data.value,
          unit: data.unit,
          time: data.time,
        };
      }
    });
    return Object.keys(vitals).map(key => vitals[key]);
  }


  /**
   * 計測対象となるデバイス情報をセット
   *
   * @param deviceModel
   */
  setBleDevice(deviceModel: BleDeviceModelType) {
    this.deviceModel = deviceModel;
  }

}

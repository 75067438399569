import { AuthStoreService } from '@srcapp/services/store/auth-store.service';
import { CalculatorService } from './../../services/vital/calculator.service';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { AbstractPage } from '@srcapp/pages/abstract-page';

// バイタル計測
import { VitalModel } from '@srcapp/models/vital.model';
import { VitalService } from '@srcapp/services/vital/vital.service';
import { VITAL_TYPES, VITAL_CONFIG } from '@srcapp/config/vital.config';
import { VitalJsonType, VitalDataType, TagType } from '@srcapp/types/vital.type';

//
import { ModalController, AlertController, Platform, ToastController, LoadingController } from '@ionic/angular';
import { DashboardService } from '@srcapp/services/dashboard.service';

// 利用者
import { PatientInfoType } from '@srcapp/types/apiResponse.type';
import { PatientListModalComponent } from '@srcapp/components/modals/patient-list-modal/patient-list-modal.component';

// スタッフ
import { StaffInfoType } from '@srcapp/types/apiResponse.type';
import { StaffListModalComponent } from '@srcapp/components/modals/staff-list-modal/staff-list-modal.component';

import { CalculatorModalComponent } from '@srcapp/components/modals/calculator-modal/calculator-modal.component';

@Component({
  selector: 'app-input-vital',
  templateUrl: './input-vital.component.html',
  styleUrls: ['./input-vital.component.scss'],
})
export class InputVitalComponent extends AbstractPage implements OnInit {

  @Input() inputVitalModel: VitalModel | null;
  /**
   * default: 通常のバイタル入力。API登録やキャンセルが可能
   * modal: Modalで表示される画面。API送信はせず、保存された場合のみ呼び出し元に返す
   */
  @Input() mode: string  = 'default'; // default or edit

  @Output() closeEvent = new EventEmitter<any>();

  tagList: TagType[] = [];

  vitalModel: VitalModel;

  isAutoSave: boolean = true;

  VITAL_CONFIG = VITAL_CONFIG;
  VITAL_TYPES = VITAL_TYPES;
  vitalTypeList: VITAL_TYPES[] = [];

  /**
   * 選択中の利用者
   */
  selectedPatient: PatientInfoType | { patientId: string, name: string, icon?: string, roomName?: string, bedNo?: string} = null;
  /**
   * 選択中のスタッフ
   */
  selectedStaff: StaffInfoType = null;
  /**
   * 選択中のバイタル
   */
  selectVitalType: VITAL_TYPES;
  selectVitalData: VitalDataType;

  calculator:any = {};
  inputText:string = '';


  isTabletMode: boolean = true;

  omronDataCache: string[] = [];

  constructor(
    public platform: Platform,
    public vitalService: VitalService,
    public calculatorService: CalculatorService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public loadingCtrl: LoadingController,
    public authStoreService: AuthStoreService,
    public dashboardService: DashboardService,
    public changeDetectorRef: ChangeDetectorRef,
  ) {
    super();

    /**
     * 電卓で入力された値を受け取り画面に反映する処理
     */
    this.calculatorService.update$.pipe(takeUntil(this.onDestroy$)).subscribe((vitalDataType) => {
      const type = vitalDataType.vitalType;
      this.vitalModel.setVital(type, vitalDataType, this.isAutoSave);
      this.changeDetectorRef.detectChanges();
    });
    /**
     * 電卓が閉じたときのイベント
     */
    this.calculatorService.close$.pipe(takeUntil(this.onDestroy$)).subscribe((vitalDataType) => {
      this.selectVitalType = null;
    });

    /**
     * IoT機器などでデータを受信したときの処理
     */
    this.vitalService.updateVitalData$.pipe(takeUntil(this.onDestroy$)).subscribe(async (vitalDataList) => {
      console.log('***** バイタルデータ取得完了 *****');
      console.log(vitalDataList);
      const data = vitalDataList[0];
      if (!data) return ;

      let aaa = JSON.stringify(vitalDataList);
      const isFind = this.omronDataCache.find(_str => {
                        return _str == aaa;
                      });
      if (isFind) {
        console.log('既に取得したデータのため何もしない');
        return ;
      }
      this.omronDataCache.push(aaa);


      vitalDataList.forEach(vitalDataType => {
        const type = vitalDataType.vitalType;
        this.vitalModel.setVital(type, vitalDataType, this.isAutoSave);
      });
      const toast = await this.toastCtrl.create({
        message: 'バイタルデータを取得しました',
        duration: 5000
      });
      toast.present();
      this.changeDetectorRef.detectChanges();
    });
  }

  ionViewDidEnter(){
    this.omronDataCache = [];
  }

  async ngOnInit() {
    this.omronDataCache = [];
    this.initVitalModel();

    // モーダルで表示する場合は、自動保存をOFFにする
    if (this.mode === 'edit') {
      this.isAutoSave = false;
    }
    this.vitalTypeList = await this.vitalService.getVitalTypeList();

    this.isTabletMode = this.checkTabletMode(this.platform);

    this.tagList = await this.vitalService.getTagList();

  }

  initVitalModel() {
    const staff = this.authStoreService.userInfo;

    this.inputText = '';

    // 新規登録の場合は、 vitalModel がnull
    this.vitalModel = this.vitalService.createVitalMode();
    if (this.inputVitalModel) {
      // inputVitalModel をそのまま利用するとシングルトンとして別のオブジェクトも書き換わってしまうため
      this.vitalModel.importJson(this.inputVitalModel.exportJson());
      this.inputText = this.vitalModel.data.comment;
    } else {
      this.vitalModel.data.staffId = staff.staffId;
      this.vitalModel.data.staffName = staff.staffName;
    }



    this.selectedPatient = null;
    this.selectVitalType = null;


    if (this.vitalModel.data.staffId) {
      const data = this.vitalModel.data;
      this.selectedStaff = {
        staffId: data.staffId,
        name: data.staffName,
        nameKana: "",
        gender: "",
        role: "",
        staffLoginId: "",
        wardName: "",
      };
    } else {
      this.selectedStaff = {
        staffId: staff.staffId,
        name: staff.staffName,
        nameKana: staff.staffNameKana,
        gender: staff.gender,
        role: staff.role,
        staffLoginId: staff.staffLoginId,
        wardName: staff.wardName
      };
    }

    // 利用者さんの選択済みの場合
    if (this.vitalModel.data.patientId) {
      const data = this.vitalModel.data;
      this.selectedPatient = {
        patientId: data.patientId,
        name:data.patientName,
      };
    }

    // ページを開くたびに初期化しておく
    const forceClose = true;
    this.calculatorService.close(forceClose);
  }



  async selectVital(type: VITAL_TYPES) {
    const result = await this.calculatorService.close();
    if (!result) return ;
    const vitalData = this.vitalModel.data.vitals[type];
    this.selectVitalType = type;
    this.selectVitalData = vitalData;

    if (!this.isTabletMode) {
      const modal = await this.showModal(this.modalCtrl, CalculatorModalComponent,
        {vitalType: this.selectVitalType, vitalData: this.selectVitalData},
        {showBackdrop: true, backdropDismiss: false, animated: false}
      );
      modal.onDidDismiss().then(() => {
        this.selectVitalType = null;
      });
    }
  }


  async cancel(){
    const alert = await this.alertCtrl.create({
      header: 'キャンセル処理',
      // subHeader: 'Subtitle',
      message: '入力データを初期化しても良いですか？',
      buttons: [
        {
          text: 'はい',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.initVitalModel();
          }
        }, {
          text: 'いいえ',
          handler: () => {

          }
        }
      ]
    });
    await alert.present();
  }

  async regist() {
    const close = await this.calculatorService.close();
    if (!close) return ;

    // 入力値チェック
      // バイタリーの場合は、コメントのみの入力を許可する
      let options = {
        isOnlyComment: this.authStoreService.menuType === 'VITALY',
      };
    let errorMsg = this.vitalModel.getErrorMsg(options);
    if (errorMsg) {
      const alert = await this.alertCtrl.create({
        header: '入力内容の確認',
        // subHeader: 'Subtitle',
        message: errorMsg,
        buttons: ['OK']
      });
      await alert.present();

      return ;
    }

    const loading = await this.loadingCtrl.create({message: '送信中...'});
    loading.present();

    const result = await this.vitalService.sendVital(this.vitalModel.exportJson());
    console.log('API実行結果', result);
    loading.dismiss();

    if (!result) {
      const subHeader = this.vitalService.errorMessage || '';
      console.log(subHeader);

      // TODO: 失敗した場合の処理を記載
      const alert = await this.alertCtrl.create({
        header: '通信失敗',
        subHeader: subHeader,
        message: 'バイタルの連携に失敗しました。<br>しばらくしてから、<br>もう一度お試しください。<br><br>なお、計測したバイタルは<br>「未送信データ」として保存したため<br>画面を閉じても問題ありません。',
        buttons: ['OK']
      });
      await alert.present();
      return ;
    }


    // 送信完了の場合
    await this.vitalService.deleteUnsentVitalList(this.vitalModel);

    const alert = await this.alertCtrl.create({
      header: '計測データ送信完了',
      // subHeader: 'Subtitle',
      message: '計測データを送信しました。',
      buttons: ['OK']
    });
    await alert.present();
    this.dashboardService.clearCache();

    this.initVitalModel();
  }

  changeComment(text:string) {
    console.log(text);
    this.vitalModel.setComment(text, this.isAutoSave);
  }


  /**
   *
   */
  isShowPatientModal = false;
  async showPatientModal() {
    // 連打対策
    if (this.isShowPatientModal) return ;
    this.isShowPatientModal = true;
    const modal = await this.showModal(this.modalCtrl, PatientListModalComponent,
      {selectedPatient: this.selectedPatient},
      {showBackdrop: true, backdropDismiss: false});

    modal.onDidDismiss().then((res) => {
      this.isShowPatientModal = false;
      console.log(res.data);
      if (res && res.data && res.data.patient) {
        this.selectedPatient = res.data.patient;
      }
      this.vitalModel.setPatient(this.selectedPatient, this.isAutoSave);
    });
  }

  /**
   *
   */
  async showStaffModal(tab = 'staff') {
    const modal = await this.showModal(this.modalCtrl, StaffListModalComponent,
       {selectTab: tab, selectedStaff: this.selectedStaff, timestamp: this.vitalModel.time},
       {showBackdrop: true, backdropDismiss: false});

       modal.onDidDismiss().then((res) => {
      console.log(res.data);
      if (res && res.data) {
        if (res.data.staff) {
          this.selectedStaff = res.data.staff;
        }
        if (res.data.timestamp) {
          this.vitalModel.setTime(res.data.timestamp, this.isAutoSave);
        }

      }
      this.vitalModel.setStaff(this.selectedStaff, this.isAutoSave);
    });
  }


  // ****************************
  // * Modal で開いている場合の処理
  // ****************************
  async editClose() {
    this.closeEvent.next({mode: this.mode, action: 'close'});
  }
  async editSave() {
    this.closeEvent.next({mode: this.mode, action: 'save', vitalModel: this.vitalModel});
  }


  getSize() {
    if (this.isTabletMode) {
      return 6;
    } else {
      return 12;
    }
  }

  addTag(tag: TagType) {
    this.vitalModel.addTag(tag);
  }
  removeTag(tag: TagType) {
    this.vitalModel.removeTag(tag);
  }
  isTag(tag: TagType) {
    return this.vitalModel.isTag(tag);
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiStaffService } from '@srcapp/services/api/api-staff.service'
import { StaffInfoType } from '@srcapp/types/apiResponse.type';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  public listSource = new Subject<any>();
  public list$ = this.listSource.asObservable();

  CACHE_ALL_STAFF_LIST = [];
  CACHE_ALL_STAFF_TIME = 0;

  constructor(public apiStaffService: ApiStaffService) {}

  clearCache() {
    this.CACHE_ALL_STAFF_LIST = [];
    this.CACHE_ALL_STAFF_TIME = 0;
  }

  async getList(): Promise<StaffInfoType[]> {
    const timestamp = moment().unix();
    // 5分間は、キャッシュを保持する
    if (this.CACHE_ALL_STAFF_LIST.length > 0 && (this.CACHE_ALL_STAFF_TIME + 300) > timestamp) {
      return this.CACHE_ALL_STAFF_LIST;
    }
    const data = await this.apiStaffService.getList();
    this.CACHE_ALL_STAFF_TIME = timestamp;
    this.CACHE_ALL_STAFF_LIST = (data) ? data.staffList : [];
    return this.CACHE_ALL_STAFF_LIST;
  }

  async getById(staffId: string): Promise<StaffInfoType> {
    const list = await this.getList();
    return list.find((s) => {
      return s.staffId === staffId;
    });
  }

  async create(staff: Partial<StaffInfoType>): Promise<boolean> {
    const result = await this.apiStaffService.create(staff);
    if (result.msg === 'success.') {
      this.clearCache();
    }
    return (result.msg === 'success.');
  }

  async update(staff: StaffInfoType): Promise<boolean> {
    if (!staff.staffId) {
      return false;
    }
    const result = await this.apiStaffService.update(staff);
    if (result.msg === 'success.') {
      this.clearCache();
    }
    return (result.msg === 'success.');
  }

  async deleteById(staffId): Promise<boolean> {
    const result = await this.apiStaffService.deleteById(staffId);
    if (result.msg === 'success.') {
      this.clearCache();
    }
    return (result.msg === 'success.');
  }
}

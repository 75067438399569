import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { VitalModel } from '@srcapp/models/vital.model';

@Component({
  selector: 'app-vital-edit-modal',
  templateUrl: './vital-edit-modal.component.html',
  styleUrls: ['./vital-edit-modal.component.scss'],
})
export class VitalEditModalComponent implements OnInit {

  @Input() vitalModel: VitalModel;

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}

  closeEvent(event) {
    console.log(event);
    let res = null;
    if (event && event.vitalModel) {
      res = {vitalModel: event.vitalModel};
    }
    this.modalCtrl.dismiss(res);
  }
}

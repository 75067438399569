import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import { AbstractPage } from '@srcapp/pages/abstract-page';
import { PatientService } from '@srcapp/services/master/patient.service';
import { AuthStoreService } from '@srcapp/services/store/auth-store.service';
import { PatientMultiSelectListComponent } from '@srcapp/components/patient-multi-select-list/patient-multi-select-list.component';

import { PatientInfoType } from '@srcapp/types/apiResponse.type';

@Component({
  selector: 'app-patient-multi-select-list-modal',
  templateUrl: './patient-multi-select-list-modal.component.html',
  styleUrls: ['./patient-multi-select-list-modal.component.scss'],
})
export class PatientMultiSelectListModalComponent extends AbstractPage implements OnInit {

  isAdultDayCare = true;


  // **** **** ****
  // * 絞り込み
  // **** **** ****
  //- 当日絞り込み
  date: string = moment().format('YYYY-MM-DD');
  targetPatientIdList:{patientId:string}[]|null = null;
  selectedTargetDate:string = this.patientService.selectedTargetDate;
  // - 五十音
  kanaList: any = [];
  selectedKana: string = this.patientService.selectedKana;
  // - ユニット絞り込み
  unitList = [];
  selectedUnit: string = this.patientService.selectedUnit;
  // - 部屋絞り込み
  roomList = [];
  selectedRoom:string =  this.patientService.selectedRoom;


  @Input() selectedPatientList: PatientInfoType[] | null;
  @ViewChild(PatientMultiSelectListComponent) list: PatientMultiSelectListComponent;

  constructor(
    public modalController: ModalController,
    private authStoreService: AuthStoreService,
    public patientService: PatientService,
  ) {
    super();
    this.patientService.listUnitAndRoom$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.unitList = data.unitList;
      this.roomList = data.roomList;
    });
  }

  ngOnInit() {
    this.isAdultDayCare = this.authStoreService.isAdultDayCare;
  }

  closeEvent() {
    this.modalController.dismiss({
      selectedPatientList: this.list.selectedPatientList
    });
  }

  onClickCancelButton() {
    this.modalController.dismiss();
  }
}

export const VITALY_APP_MENU = [
  {
    groupName: '見る',
    menus: [
      {
        title: '本日のバイタル',
        url: '/top',
        icon: 'calendar',
        index: 201,
      },
    ],
  },
  {
    groupName: '記録する',
    menus: [
      {
        title: 'バイタル 通常計測',
        url: '/vital-single',
        icon: 'heart',
        index: 101,
      },
      {
        title: 'バイタル 複数人計測',
        url: '/vital-multi',
        icon: 'heart-circle',
        index: 102,
      },
      {
        title: 'バイタル 連続計測',
        url: '/vital-sequential',
        icon: 'heart-circle',
        index: 103,
      },
      {
        title: 'バイタル 未送信データ',
        url: '/vital-unsent',
        icon: 'cloud-upload',
        index: 104,
      },
    ]
  },
  {
    groupName: 'ログ',
    menus: [
      {
        title: '操作ログ管理',
        url: '/action-logs',
        icon: 'archive',
        index: 301,
      },
    ],
  },
  {
    groupName: '設定',
    menus: [
      {
        title: 'デバイス管理',
        url: '/setting-ble-device',
        icon: 'bluetooth',
        index: 901,
      },
      {
        title: 'パスワード変更',
        url: '/change-password',
        icon: 'warning',
        index: 902,
      }
    ],
  },
  {
    groupName: 'その他',
    menus: [
      {
        title: 'お知らせ',
        url: '/news',
        icon: 'newspaper',
        index: 905,
      },
      {
        title: 'このアプリについて',
        url: '/about',
        icon: 'settings',
        index: 906,
      },
      // {
      //   title: 'ログアウト',
      //   url: '/logout',
      //   icon: 'log-out',
      //   index: 907,
      // },
    ],
  }
];
export const VITALY_WEB_MENU = [
  {
    groupName: '見る',
    menus: [
      {
        title: '本日のバイタル',
        url: '/top',
        icon: 'calendar',
        index: 201,
      },
      {
        title: 'バイタル記録を見る',
        url: '/carerecord',
        icon: 'calendar',
        index: 202,
      },
    ],
  },
  {
    groupName: '記録する',
    menus: [
      {
        title: 'バイタル 通常計測',
        url: '/vital-single',
        icon: 'heart',
        index: 101,
      }
    ]
  },
  {
    groupName: 'マスター管理',
    menus: [
      {
        title: '利用者情報管理',
        url: '/master-patient-list',
        icon: 'bluetooth',
        index: 903,
      },
      {
        title: 'スタッフ情報管理',
        url: '/master-staff-list',
        icon: 'warning',
        index: 904,
      }
    ],
  },
  {
    groupName: '設定',
    menus: [
      {
        title: 'パスワード変更',
        url: '/change-password',
        icon: 'warning',
        index: 902,
      }
    ],
  },
  {
    groupName: 'その他',
    menus: [
      {
        title: 'お知らせ',
        url: '/news',
        icon: 'newspaper',
        index: 905,
      },
      {
        title: 'このアプリについて',
        url: '/about',
        icon: 'settings',
        index: 906,
      },
      {
        title: 'ログアウト',
        url: '/logout',
        icon: 'log-out',
        index: 907,
      },
    ],
  }
];

export const WSP_MENU = [
  {
    groupName: '見る',
    menus: [
      {
        title: '本日のバイタル',
        url: '/top',
        icon: 'calendar',
        index: 201,
      },
    ],
  },
  {
    groupName: '記録する',
    menus: [
      {
        title: 'バイタル 通常計測',
        url: '/vital-single',
        icon: 'heart',
        index: 101,
      },
      {
        title: 'バイタル 複数人計測',
        url: '/vital-multi',
        icon: 'heart-circle',
        index: 102,
      },
      {
        title: 'バイタル 連続計測',
        url: '/vital-sequential',
        icon: 'heart-circle',
        index: 103,
      },
      {
        title: 'バイタル 未送信データ',
        url: '/vital-unsent',
        icon: 'cloud-upload',
        index: 104,
      },
    ]
  },
  {
    groupName: 'ログ',
    menus: [
      {
        title: '操作ログ管理',
        url: '/action-logs',
        icon: 'archive',
        index: 301,
      },
    ],
  },
  {
    groupName: '設定',
    menus: [
      {
        title: 'デバイス管理',
        url: '/setting-ble-device',
        icon: 'bluetooth',
        index: 901,
      },
      {
        title: 'パスワード変更',
        url: '/change-password',
        icon: 'warning',
        index: 902,
      }
    ],
  },
  // {
  //   groupName: 'マスター管理',
  //   menus: [
  //     {
  //       title: '利用者情報管理',
  //       url: '/master-patient-list',
  //       icon: 'bluetooth',
  //       index: 903,
  //     },
  //     {
  //       title: 'スタッフ情報管理',
  //       url: '/master-staff-list',
  //       icon: 'warning',
  //       index: 904,
  //     }
  //   ],
  // },
  {
    groupName: 'その他',
    menus: [
      {
        title: 'お知らせ',
        url: '/news',
        icon: 'newspaper',
        index: 905,
      },
      {
        title: 'このアプリについて',
        url: '/about',
        icon: 'settings',
        index: 906,
      },
      {
        title: 'ログアウト',
        url: '/logout',
        icon: 'log-out',
        index: 907,
      },
    ],
  }
];
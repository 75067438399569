import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CalculatorService } from './../../../services/vital/calculator.service';
import { AbstractPage } from './../../../pages/abstract-page';
import { takeUntil } from 'rxjs/operators';
import { VITAL_TYPES } from '@srcapp/config/vital.config';
import { VitalDataType } from '@srcapp/types/vital.type';

@Component({
  selector: 'app-calculator-modal',
  templateUrl: './calculator-modal.component.html',
  styleUrls: ['./calculator-modal.component.scss'],
})
export class CalculatorModalComponent extends AbstractPage implements OnInit {

  @Input() vitalType: VITAL_TYPES = null;
  @Input() vitalData: VitalDataType = null;

  selectVitalType;
  selectVitalData;

  constructor(
    public modalCtrl: ModalController,
    public calculatorService: CalculatorService
  ) {
    super();
    /**
     * 電卓が閉じたときのイベント
     */
    this.calculatorService.close$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.modalCtrl.dismiss();
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.selectVitalType = this.vitalType;
      this.selectVitalData = this.vitalData;
    }, 300);
  }

}

import { Injectable } from '@angular/core';
import { VitalJsonType } from '@srcapp/types/vital.type';
import { ApiAbstractService } from './api-abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ApiVitalService extends ApiAbstractService {


  async sendVital(postData: VitalJsonType) {
    return this.post(`${this.getBaseApi()}/api/v1/vitals`, postData);
  }

  async updateCloudVital(vitalId: string, postData: VitalJsonType) {
    return this.post(`${this.getBaseApi()}/api/v1/vitals/${vitalId}`, postData);
  }

  /**
   * バイタルグラフを描画するためのAPI
   */
  async getPersonalVitalBetween(params: {
    patientId: string,
    start: number,
    end: number,
    types: string[],
  }) {
    const url = `${this.getBaseApi()}/api/v1/vitals/${params.patientId}?start=${params.start}&end=${params.end}&types=${params.types.join(',')}`
    return this.get(url);
  }

  /**
   * CSV出力するためのAPI
   */
  async getPersonalVitalOutPutCSV(params: {
    patientId: string,
    start: number,
    end: number,
  }) {
    const url = `${this.getBaseApi()}/api/v1/vitals/${params.patientId}/csvoutput?start=${params.start}&end=${params.end}`
    return this.get(url);
  }

  async deleteDBVital(vitalId) {
    const url = `${this.getBaseApi()}/api/v1/vitals/${vitalId}`
    return this.delete(url);
  }

  /**
   * タグ取得
   */
  async getApiTaglist() {
    const url = `${this.getBaseApi()}/api/v1/tags`;
    return this.get(url);
  }
}

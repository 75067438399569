import { Injectable } from '@angular/core';
import { ExportDeviceJsonType, BleDeviceModelType } from '@srcapp/types/bleDeviceModel.typs';
import { BleService } from './ble/ble.service';
import { Storage } from '@ionic/storage';


const STORAGE_DEVICE_NAME = 'storage.devices';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  deviceList: BleDeviceModelType[] = [];
  constructor(private bleService: BleService, private storage: Storage) {
    this.getDeviceList();
  }

  /**
   * ストレージからペアリングデバイス情報を取得して
   * Modelにセットした情報を返す
   */
  async getDeviceList(): Promise<BleDeviceModelType[]> {
    if ( this.deviceList.length === 0) {
      let data: ExportDeviceJsonType[] | null = await this.storage.get(STORAGE_DEVICE_NAME);
      this.deviceList = (data || []).map((json) => {
        return this.bleService.getDeviceModel(json.device, json.params);
      }).filter(d => d);
    }
    return this.deviceList;
  }


  /**
   * deviceIdを元に既に接続済みのデバイスかどうかをチェックする
   */
  findByDeviceId(deviceId: string) {
    return this.deviceList.find((d) => deviceId === d.deviceId);
  }

  /**
   * デバイス情報をストレージに保存する
   */
  async seveDevice(deviceModel: BleDeviceModelType) {
    let isNew = true;
    const deviceList = this.deviceList.map(device => {
      if (device.deviceId === deviceModel.deviceId) {
        isNew = false;
        return deviceModel;
      }
      return device;
    });
    if (isNew) {
      deviceList.push(deviceModel);
    }
    this.deviceList = deviceList;

    // 出力してストレージに保存する
    const json = this.deviceList.map(device => device.exportJson());
    await this.storage.set(STORAGE_DEVICE_NAME, json);
  }

  async deleteDevice(deviceModel: BleDeviceModelType) {
    const deviceList = this.deviceList.filter(device => {
      if (device.deviceId === deviceModel.deviceId) {
        return false;
      }
      return true;
    });
    this.deviceList = deviceList;
    // 出力してストレージに保存する
    const json = this.deviceList.map(device => device.exportJson());
    console.log('保存データ', json);
    await this.storage.set(STORAGE_DEVICE_NAME, json);

    this.getDeviceList();
  }
}

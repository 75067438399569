import { AuthStoreService } from '@srcapp/services/store/auth-store.service';
import { Component, OnInit } from '@angular/core';

import { Platform, ToastController, AlertController, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Router } from '@angular/router';
import { UtilService } from '@srcapp/services/util.service';

import { VITALY_APP_MENU, VITALY_WEB_MENU, WSP_MENU } from '@srcapp/config/page.config';
import { DashboardService } from './services/dashboard.service';
import { PatientService } from './services/master/patient.service';
import { StaffService } from './services/master/staff.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPageGroups = [];

  isLogin: boolean = false;


  constructor(
    private platform: Platform,
    public router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private androidPermissions: AndroidPermissions,
    public authStoreService: AuthStoreService,
    public toastController: ToastController,
    public loadingController: LoadingController,
    public utilService: UtilService,
    private dashboardService: DashboardService,
    private patientService: PatientService,
    private staffService: StaffService,
  ) {
    this.initializeApp();

    this.authStoreService.login$.subscribe(() => {
      console.log('ログイン');
      this.isLogin = true;
      if (this.authStoreService.menuType === 'VITALY') {
        if (this.platform.is('cordova')) {
          this.appPageGroups = VITALY_APP_MENU;
        } else {
          this.appPageGroups = VITALY_WEB_MENU;
        }
      } else if (this.authStoreService.menuType === 'WSP') {
        this.appPageGroups = WSP_MENU;
      }
    });
    this.authStoreService.logout$.subscribe(() => {
      console.log('ログアウト');
      this.isLogin = false;
      this.router.navigateByUrl('/login')
    });


    // APIでプログラムの更新通知があった場合のみ、CodePushの情報を参照するようにする
    this.utilService.updateApplication$.subscribe(async (data: { forceUpdate: boolean }) => {
      console.log('[codePush] checking');
      const toast = await this.toastController.create({
        message: '[更新確認] 更新プログラムの確認をしています...',
        duration: 10000,
        // color: this.isDayMode ? '' : 'dark',
      });
      toast.present();
    });

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
        result => console.log('Has permission?',result.hasPermission),
        err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION)
      );
      this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION]);

      // this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_BACKGROUND_LOCATION).then(
      //   result => console.log('Has permission?',result.hasPermission),
      //   err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_BACKGROUND_LOCATION)
      // );
      // this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.ACCESS_BACKGROUND_LOCATION]);
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      const pages = this.appPageGroups.reduce((array, group) => [...group.menus], []);
      this.selectedIndex = pages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  async logout() {
    await this.authStoreService.removeAuthUserInfo();
    // キャッシュのクリア
    this.dashboardService.clearCache();
    this.patientService.clearCache();
    this.staffService.clearCache();

    this.router.navigateByUrl('/login')
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DeviceService } from '@srcapp/services/device.service';
import { BleService } from '@srcapp/services/ble/ble.service';
import { BleDeviceModelType } from '@srcapp/types/bleDeviceModel.typs';
import { AbstractPage } from './../../../pages/abstract-page';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-device-select-modal',
  templateUrl: './device-select-modal.component.html',
  styleUrls: ['./device-select-modal.component.scss'],
})
export class DeviceSelectModalComponent  extends AbstractPage implements OnInit {

  deviceList: BleDeviceModelType[] = [];
  @Input() selectedDeviceModel: BleDeviceModelType = null;

  constructor(
    public modalCtrl: ModalController,
    private deviceService: DeviceService,
    private bleService: BleService,
  ) {
    super();

  }

  async ngOnInit() {
    await this.initDeviceList();
  }


  save() {
    this.modalCtrl.dismiss({ device: this.selectedDeviceModel});
  }

  select(device: BleDeviceModelType) {
    this.selectedDeviceModel = device;
  }

  isSelected(deviceId: string): boolean {
    if (!this.selectedDeviceModel) return false;
    return (this.selectedDeviceModel.deviceId === deviceId);
  }
  // ペアリングを開始するボタン
  async startParing() {
    // 他にモーダルが出ているので、呼び出し元ページに画面遷移は任せる
    this.modalCtrl.dismiss({
      event: 'startParing'
    });
  }

  async initDeviceList() {

    // ストレージからペアリング済みの情報を読み取る
    this.deviceList = await this.deviceService.getDeviceList();

    // let device = {id: '123456789', name: 'A&D_UT201BLE_1E1E', rssi: 0, advertising: []};
    // const deviceModel = this.bleService.getDeviceModel(device);
    // deviceModel.isDebug = true;
    // this.deviceList = [
    //   deviceModel
    // ];
  }
}

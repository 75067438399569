import { BLE } from '@ionic-native/ble/ngx';
import { BleDeviceModelType, BleScanDeviceJsonType, ExportDeviceJsonType } from '@srcapp/types/bleDeviceModel.typs'
import { VitalDataDeviceType } from '@srcapp/types/vital.type';
import { MASTER_DEVICE_COLOR_LABEL } from './../../config/label.config';
import { Subject } from "rxjs";


export enum DEVICE_TYPES {
  /**
   * 体温計: Thermometer
   */
  THE = 'THE',
  /**
   * 血圧計: Sphygmomanometer
   */
  SPH = 'SPH',
  /**
   * パルスオキシメーター: Pulse Oximeter
   */
  PUL = 'PUL',
}

// export const DEVICE_TYPE_INFO = {
//   [DEVICE_TYPES.THE]: {
//     deviceTypeName: '体温計'
//   },
//   [DEVICE_TYPES.SPH]: {
//     deviceTypeName: '血圧計'
//   },
//   [DEVICE_TYPES.PUL]: {
//     deviceTypeName: 'パルスオキシメーター'
//   },
// }


export abstract class AbstractDevice implements BleDeviceModelType {
  ble: BLE;

  // Scanしたデバイス情報を保持する
  device: BleScanDeviceJsonType;
  deviceId: string;
  
  // メーカー名
  abstract makerCode: string;
  abstract makerName: string;
  abstract productCode: string;
  abstract productName: string;
  abstract deviceType: string;
  abstract deviceTypeName: string;
  abstract deviceImage: string;


  name: string;
  labelInfo = null;

  /**
   * デバッグの場合は、BLE接続しないのでここに定義
   */
  isDebug = false;

  vitals$ = new Subject();
  connectDevice$ = new Subject();

  /**
   * 
   * @param ble BLE Bluetooth接続情報
   * @param device BleScanDeviceJsonType SACNして取得したデバイス情報
   */
  constructor(ble: BLE, device: BleScanDeviceJsonType, params: { name: string, labelId: string } = null) {
    this.ble = ble;
    this.device = device;
    this.deviceId = device.id;

    if (params) {
      this.name = params.name;
      this.labelInfo = MASTER_DEVICE_COLOR_LABEL.find(data => data.id === params.labelId) || null;
    }
  }

  /**
   * 端末のストレージ保存用に出力されるJSONフォーマット
   */
  exportJson(): ExportDeviceJsonType {
    return {
      deviceId: this.deviceId,
      // SCAN時の情報
      device: this.device,
      // 端末で設定した情報
      params: {
        name: this.name,
        labelId: this.labelInfo ? this.labelInfo.id : ''
      }
    };
  }

  /**
   * デバイス情報を返す
   */
  getDeviceInfo(): VitalDataDeviceType {
    return {
      deviceId: this.deviceId,
      makerCode: this.makerCode,
      makerName: this.makerName,
      productCode: this.productCode,
      productName: this.productName,
      deviceType: this.deviceType,
      deviceTypeName: this.deviceTypeName,
      deviceImage: this.deviceImage,
      name: this.name,
      labelId: this.labelInfo ? this.labelInfo.id : "",
    }
  }
  /**
   * 端末毎にペアリング動作がことなるので子クラスで実装する
   */
  abstract async paringInitData(): Promise<boolean>;

  // TODO: 型は後ほどセット
  abstract async getData(): Promise<any>;

  /**
   * 強制的に接続を解除させる処理
   */
  async disconnect(): Promise<void> {
    await this.ble.disconnect(this.deviceId).then(()=> true).catch(() => false);
  }

  async sleep(time = 300) {
    await new Promise((resolve) => {
      setTimeout(() => resolve(), time);
    })
  }
}

import { AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { VitalDataType } from '@srcapp/types/vital.type';
import { VITAL_TYPES, VITAL_CONFIG } from '@srcapp/config/vital.config';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class CalculatorService {

  vitalData: VitalDataType;
  vitalConfig: {
    name: string, // '体温',
    unit: string, // '℃',
    min: number, // 34.0,
    max: number, // 42.0
  };
  value:string = '';
  validationMsg: string = '';
  errorMsg: string = '';

  /**
   * 電卓の値が変更されたときの処理
   */
  updateSource = new Subject<VitalDataType>();
  update$ = this.updateSource.asObservable();

  closeSource = new Subject<void>();
  close$ = this.closeSource.asObservable();


  constructor(
    public alertCtrl: AlertController
  ) {
  }

  public setVitalData(vitalType: VITAL_TYPES, vitalData: VitalDataType = null) {
    if (!VITAL_CONFIG[vitalType]) return;
    this.vitalConfig = VITAL_CONFIG[vitalType];

    if (!vitalData || !vitalData.vitalType) {
      vitalData = {
        vitalType: vitalType,
        vitalName: this.vitalConfig.name,
        value: '',
        unit: this.vitalConfig.unit,
        time: moment().unix(),
        device: null
      }
    }
    this.value = vitalData.value;
    this.errorMsg = '';
    this.validationMsg = `${this.vitalConfig.min} 〜 ${this.vitalConfig.max} の間で入力して下さい。`;
    this.vitalData = Object.assign({}, vitalData);
  }



  validate() {
    // 値が空欄のときは行う必要がない
    if (this.value === '') {
      return true;
    }
    // 保存前に入力値チェックを行う
    const config = this.vitalConfig;
    if (!config) return true;
    const num = Number(this.value);
    return (config.min <= num && num <= config.max);
  }

  clear(): void {
    this.value = '';
    this.callCallback();
  }

  async close(forceClose: boolean = false) {

    if (forceClose) {
      this.clear();
    }
  
    // サービス側で判定処理をさせたい
    if (!this.validate()) {
      const alert = await this.alertCtrl.create({
        header: '入力値が無効です',
        // subHeader: 'Subtitle',
        message: this.validationMsg,
        buttons: ['OK']
      });
      await alert.present();
      return false;
    }

    // 値が.で終わっている場合は、
    if ('.' === this.value.slice(-1)) {
      this.clickNum('0');
    }
    this.value = '';
    this.vitalData = null;
    this.vitalConfig = null;
    this.validationMsg = '';
    this.errorMsg = '';

    this.closeSource.next();
    return true;
  }

  cancel(): void {
    this.value = this.value.slice( 0, -1 );
    if (!this.value) {
      this.clear();
    }
    this.callCallback();
  }

  clickNum(value: string): void {

    if (this.value === '0') {
      this.value = value;
      this.callCallback();
      return;
    }
    // 「.」が入力されている場合「.」 は反応させない
    if (this.value.indexOf('.') != -1 && value === '.') {
      this.callCallback();
      return;
    }
    // 次の文字が追加されることで
    // - 上限を超える場合: 4桁になる場合
    // - 加減を超える場合: 小数点以下4桁
    let tmpValue = this.value + value;
    // 4桁以上になる場合は処理しない
    if (Number(tmpValue) >= 1000) {
      return;
    }

    // 少雨数点以下3桁以上はいかない
    if (typeof tmpValue === 'string') {
      // 小数点で文字列分解。 ex) 36.5 => ["36", "5"];
      let tmp = tmpValue.split('.');
      // 小数部分が存在していて、かつ小数点以下3桁以上のデータになる場合は何も処理しない
      if (tmp.length > 1 && tmp[1].length > 2) {
          return ;
      }
    }

    this.value += value;
    this.callCallback();
  }

  callCallback() {
    if (this.vitalData) {
      this.vitalData.value = this.value;
      this.updateSource.next(this.vitalData);
    }
  }

}

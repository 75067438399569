// TODO: 共通の設定情報として保持しておきたい
export enum VITAL_TYPES {
  BODY_TEMPERATURE = 'BT',// 体温
  BLOOD_PRESSURE_UP = 'BPH', // 血圧（上）
  BLOOD_PRESSURE_DOWN = 'BPL', // 血圧（下）
  PLUSE = 'PR', // 脈拍数
  SPO2 = 'SpO2', // SpO2
  RESPIRATION = 'RR', // 呼吸数
  BLOOD_GLUCOSE_LEVEL = 'BS', // 血糖値
  BODY_HEIGHT = 'BH', // 身長
  BODY_WEIGHT = 'BW', // 体重
}

export const VITAL_CONFIG = {
  // 体温
  [VITAL_TYPES.BODY_TEMPERATURE]: {
    name: '体温',
    unit: '℃',
    min: 34.0,
    max: 42.0
  },
  // 血圧（上）
  [VITAL_TYPES.BLOOD_PRESSURE_UP]: {
    name: '血圧（上）',
    unit: 'mmhg',
    min: 0,
    max: 320
  },
  // 血圧（下）
  [VITAL_TYPES.BLOOD_PRESSURE_DOWN]: {
    name: '血圧（下）',
    unit: 'mmhg',
    min: 0,
    max: 320
  },

  // 脈拍数
  [VITAL_TYPES.PLUSE]: {
    name: '脈拍',
    unit: '回',
    min: 30,
    max: 240
  },
  // SpO2
  [VITAL_TYPES.SPO2]: {
    name: 'SpO2',
    unit: '%',
    min: 0,
    max: 100
  },
  // 呼吸数
  [VITAL_TYPES.RESPIRATION]: {
    name: '呼吸',
    unit: '回',
    min: 0,
    max: 200
  },
  // 血糖値
  [VITAL_TYPES.BLOOD_GLUCOSE_LEVEL]: {
    name: '血糖値',
    unit: 'mg/dL',
    min: 20,
    max: 600
  },
  // 身長
  [VITAL_TYPES.BODY_HEIGHT]: {
    name: '身長',
    unit: 'cm',
    min: 0,
    max: 300
  },
  // 体重
  [VITAL_TYPES.BODY_WEIGHT]: {
    name: '体重',
    unit: 'kg',
    min: 0,
    max: 300
  },
};

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@srcapp/guards/auth.guard';
import { LoginGuard } from '@srcapp/guards/login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'top',
    pathMatch: 'full'
  },
  {
    path: 'license-key',
    loadChildren: () => import('./pages/auth/license-key/license-key.module').then( m => m.LicenseKeyPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/auth/logout/logout.module').then( m => m.LogoutPageModule)
  },

  // 本日のバイタル記録
  {
    path: 'top',
    loadChildren: () => import('./pages/top/top.module').then( m => m.TopPageModule),
    canActivate: [AuthGuard]
  },
  // 記録を見る
  {
    path: 'carerecord',
    loadChildren: () => import('./pages/carerecord/carerecord.module').then( m => m.CarerecordPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'carerecord/:patientId',
    loadChildren: () => import('./pages/carerecord/carerecord.module').then( m => m.CarerecordPageModule),
    canActivate: [AuthGuard]
  },


  // 通常バイタル
  {
    path: 'vital-single',
    loadChildren: () => import('./pages/vitals/vital-single/vital-single.module').then( m => m.VitalSinglePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vital-multi',
    loadChildren: () => import('./pages/vitals/vital-multi/vital-multi.module').then( m => m.VitalMultiPageModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'vital-unsent',
    loadChildren: () => import('./pages/vitals/vital-unsent/vital-unsent.module').then( m => m.VitalUnsentPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vital-graph/:patientId',
    loadChildren: () => import('./pages/vitals/vital-graph/vital-graph.module').then( m => m.VitalGraphPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'action-logs',
    loadChildren: () => import('./pages/actions/action-logs/action-logs.module').then( m => m.ActionLogsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'setting-ble-device',
    loadChildren: () => import('./pages/settings/setting-ble-device/setting-ble-device.module').then( m => m.SettingBleDevicePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/settings/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then( m => m.NewsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule),
    canActivate: [AuthGuard]
  },
  // 利用者master
  {
    path: 'master-patient-list',
    loadChildren: () => import('./pages/master/patient/list/list.module').then( m => m.ListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'master-patient-edit/:patientId',
    loadChildren: () => import('./pages/master/patient/edit/edit.module').then( m => m.EditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'master-patient-add',
    loadChildren: () => import('./pages/master/patient/edit/edit.module').then( m => m.EditPageModule),
    canActivate: [AuthGuard]
  },
  // スタッフmaster
  {
    path: 'master-staff-list',
    loadChildren: () => import('./pages/master/staff/list/list.module').then( m => m.ListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'master-staff-edit/:staffId',
    loadChildren: () => import('./pages/master/staff/edit/edit.module').then( m => m.EditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'master-staff-add',
    loadChildren: () => import('./pages/master/staff/edit/edit.module').then( m => m.EditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vital-sequential',
    loadChildren: () => import('./pages/vitals/vital-sequential/vital-sequential.module').then( m => m.VitalSequentialPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'carerecord',
    loadChildren: () => import('./pages/carerecord/carerecord.module').then( m => m.CarerecordPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import { AbstractPage } from '@srcapp/pages/abstract-page';
import { PatientService } from '@srcapp/services/master/patient.service';
import { AuthStoreService } from '@srcapp/services/store/auth-store.service';

import { PatientInfoType } from '@srcapp/types/apiResponse.type';

@Component({
  selector: 'app-patient-list-modal',
  templateUrl: './patient-list-modal.component.html',
  styleUrls: ['./patient-list-modal.component.scss'],
})
export class PatientListModalComponent extends AbstractPage implements OnInit {

  isAdultDayCare = true;


  // **** **** ****
  // * 絞り込み
  // **** **** ****
  //- 当日絞り込み
  date: string = moment().format('YYYY-MM-DD');
  targetPatientIdList:{patientId:string}[]|null = null;
  selectedTargetDate: string = this.patientService.selectedTargetDate;
  // - 五十音
  kanaList: any = [];
  selectedKana: string = this.patientService.selectedKana;
  // - フロアー絞り込み
  wardList = [];
  selectedWard: string = 'all';
  // - ユニット絞り込み
  unitList = [];
  selectedUnit: string = this.patientService.selectedUnit;
  // - 部屋絞り込み
  roomList = [];
  selectedRoom:string =  this.patientService.selectedRoom;


  @Input() selectedPatient: PatientInfoType | null;

  constructor(
    public modalController: ModalController,
    public authStoreService: AuthStoreService,
    public patientService: PatientService,
  ) {
    super();
    this.patientService.listUnitAndRoom$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.wardList = data.wardList;
      this.unitList = data.unitList;
      this.roomList = data.roomList;
    });
  }

  ngOnInit() {
    this.isAdultDayCare = this.authStoreService.isAdultDayCare;
  }

  closeEvent(params) {
    console.log('closeEvent:', params)
    this.modalController.dismiss(params);
  }

  onClickCancelButton() {
    this.modalController.dismiss();
  }
}

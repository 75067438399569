import { AbstractDevice, DEVICE_TYPES } from './abstractDevice.model';
import { GATTService } from '@srcapp/services/ble/gatt.service';
import { BleConnectDeviceJsonType } from '@srcapp/types/bleDeviceModel.typs'
import { VITAL_TYPES } from '@srcapp/config/vital.config';
import { VitalDataType, VitalDataDeviceType } from '@srcapp/types/vital.type';
import * as moment from 'moment';


export class OmronTM101B02BLEDevice extends AbstractDevice {
  // クラス毎に設定する情報
  makerCode = 'OMRON';
  makerName = 'OMRON';
  productCode = 'TM101B02';
  productName = 'TM-101B02';
  deviceType = DEVICE_TYPES.THE;
  deviceTypeName = '体温計';
  deviceImage = './assets/imgs/devices/OMRON/TM-101B02.jpg';

  // A&Dのサービス情報を定義する
  characteristic = {
    dateTime: {
      service: '1809', // dateTimeServiceUUID
      // org.bluetooth.characteristic.date_time	
      characteristic: '2A08', // dateTimeCharacteristicUUID
    },
    // 体温を取得するイベント
    getData: {
      service: '1809',
      characteristic: '2A1C',
    },
  }

  /**
   * ペアリングの処理を司る
   */
  async paringInitData(): Promise<boolean> {
    if (this.isDebug) {
      return true;
    }
    return Promise.resolve(true);
  }

  notificationList: any[] = [];

  async getData(): Promise<void > {
    if (this.isDebug) {
      this.vitals$.next([
        {
          vitalType: VITAL_TYPES.BODY_TEMPERATURE,
          vitalName: '体温',
          value: '36.5',
          unit: '',
          time: moment().unix(),
          device: this.getDeviceInfo()
        }
      ]);
      return new Promise(resolve => {
        setTimeout(() => {
          return resolve();
        }, 3000);
      });
    }
    this.notificationList = [];
    console.log('[Worker] 1:_connect デバイスに接続する');
    console.log('接続先ID:' + this.deviceId);

  
    return new Promise((resolve) => {

      this.ble.connect(this.deviceId).subscribe(async () => {
        console.log('connect OK:' + this.deviceId);
        await this.sleep(200);

        let message = `[OMRON機器] ${this.device?.name}  接続しました。`;
        this.connectDevice$.next(message);
    

        console.log('*** OMRON接続 ****');
        // 1. Read Characteristic(DeviceInfomation)
        console.log('[OMRON]', '1. Read Characteristic(DeviceInfomation)', 'start');
        const a1 = await this.ble.read(this.deviceId, '180A', '2a29').catch((e) => e);
        console.log('[OMRON]', '1. Read Characteristic(DeviceInfomation)', 'end', a1);
    
        // 2. Read Characteristic(Blood Pressure Feature)
        console.log('[OMRON]', '2. Read Characteristic(Blood Pressure Feature)', 'start');
        const a2 = await this.ble.read(this.deviceId, '1810', '2a49').catch((e) => e);
        console.log('[OMRON]', '2. Read Characteristic(Blood Pressure Feature)', 'end', a2);

        console.log('[OMRON]', '4. Write CCCD (Battery Level)', 'start');
        this.ble.startNotification(this.deviceId, '180f', '2a19').subscribe((data) => {
          console.log('[OMRON]', '4. Write CCCD (Battery Level)', 'receive notification', data);
          this.ble.stopNotification(this.deviceId, '180f', '2a19');
        });

        this.ble.startNotification(this.deviceId, '1809', '2A1C').subscribe((res) => {
          console.log('[BleDeviceService] INFO: *** ！！！バイナリデータ受信中！！！ ***');
          console.log(res);
          console.log('[BleDeviceService] INFO: *** 解析結果 ***');
          const vitals = this.getBodyMeasurementValue(res);
          console.log(vitals);
          if (vitals) {
            this.vitals$.next(vitals);
          }
        }, (e) => {
          console.log('[ERROR] 受信待ち');
          console.log(e);
          this.ble.disconnect(this.deviceId);
          resolve();
        });

      }, (e) => {
        console.log('[ERROR] connect: ' + this.deviceId);
        console.log(e);

        this.ble.stopNotification(this.deviceId, '1809', '2A1C').then(async (res) => {
          console.log('***disconnect:then');
          console.log(res);
          await this.ble.disconnect(this.deviceId);
          resolve();
        }).catch(async (error) => {
          console.log('***stopNotification:catch');
          console.log(error);
          await this.ble.disconnect(this.deviceId);
          resolve();
        });
    
      });

    });
  }



  getBodyMeasurementValue(res): VitalDataType[] {
    let offset = 1;
    var result = 0;

    if (res && Array.isArray(res)) {
      res = res[0];
    }
    let dv = new DataView(res);

    // 1byte目
    let byte1 = dv.getUint8(0);
    var littleEndian = true;

    // C1方式かC2方式化を選択する
    // 0bit: 0=Celsius (C1), 1=Fahrenheit (C2)
    if ((byte1 & 0x01) === 0) {
      // C1方式　2byte目~5byte目
      var buffer = new ArrayBuffer(32);
      var data = new DataView(buffer);
      data.setUint32(0, dv.getUint32(offset));

      result = GATTService.readFLOAT(data, littleEndian);
      console.log(result);

      // 4byte読み込んだので+4
      offset += 4;
    } else {
      // C2方式　2byte目~5byte目
      console.log('C2方式では読み込めません')
    }

    var measuredTime = null;

    // 時間情報があるかないか
    // 1bit: 0: NULL, 1: Time Stamp field present(C3)
    if ((byte1 & 0x02) === 0) {
      // 時間がないのでパス
      console.log('時間情報なし');
    } else {
      // 時間設定を 6byte目~12byte目までが時間情報
      measuredTime = GATTService.readDateTime(dv, offset, littleEndian);
      offset += 7;
    }

    // 計測状態を取得する
    // 2bit: 0: NULL, 1: Type field present (C4) (fixed)
    if ((byte1 & 0x03) === 0) {
      // 終了
    } else {
      // 計測状態を取得する
    }


    // A&D側でエラーが発生した場合に8388607などよくわからない数字が渡ってくることがある
    // そのため、ここでは上限値を超えているデータについては握りつぶすことにする
    if (result < 30 || 50 < result) {
      console.log('[BleDeviceService] WARNING: *** エラーの値を取得したため処理としては握りつぶす ***');
      console.log(result);
      // TODO: rejectしても良いかも！？ 
      return null;
    }

    measuredTime = Math.floor(new Date().getTime() / 1000);

    const vitals = [];
    vitals.push({
      vitalType: VITAL_TYPES.BODY_TEMPERATURE,
      vitalName: '体温',
      value: `${(result).toFixed(1)}`,
      unit: '',
      time: measuredTime,
      device: this.getDeviceInfo()
    });
    return vitals;
  }

}

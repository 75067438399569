import { Injectable } from '@angular/core';
import { ApiDashboardService } from './api/api-dashboard.service';
import { AuthStoreService } from '@srcapp/services/store/auth-store.service';
import * as moment from 'moment';
const API_STORAGE_KEY = 'ApiHobby';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  CACHE_TODAY_DATA = [];
  CACHE_TODAY_TIME = 0;

  constructor(public apiDashboardService: ApiDashboardService, public authStoreService: AuthStoreService) {}

  clearCache() {
    this.CACHE_TODAY_DATA = [];
    this.CACHE_TODAY_TIME = 0;
  }

  /**
   * 当日のバイタル記録を取得する
   */
  async getTodayData(): Promise<any> {
    const timestamp = moment().unix();
    // 5分間は、キャッシュを保持する
    if (this.CACHE_TODAY_DATA.length > 0 && (this.CACHE_TODAY_TIME + 300) > timestamp) {
      return this.CACHE_TODAY_DATA;
    }
    const res: any = await this.apiDashboardService.getTodayData();
    this.CACHE_TODAY_TIME = timestamp;
    this.CACHE_TODAY_DATA = (res) ? res.vitals : [];
    return this.CACHE_TODAY_DATA;
  }

}


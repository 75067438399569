// ラベルに付与する情報
export const MASTER_DEVICE_COLOR_LABEL = [
  {'id': 'label_1', 'name': '赤', 'cssClass': 'label-red'},
  {'id': 'label_2', 'name': '緑', 'cssClass': 'label-green'},
  {'id': 'label_3', 'name': '青', 'cssClass': 'label-blue'},
  {'id': 'label_4', 'name': '黄', 'cssClass': 'label-yellow'},
  {'id': 'label_5', 'name': 'オレンジ', 'cssClass': 'label-orange'},
  {'id': 'label_6', 'name': '紫', 'cssClass': 'label-purple'},
  {'id': 'label_7', 'name': 'ピンク', 'cssClass': 'label-pink'},
  {'id': 'label_8', 'name': '黒', 'cssClass': 'label-black'},
];
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  // ログアウトボタンがおされたときのイベント
  onClickLogoutSource = new Subject<void>();
  onClickLogout$ = this.onClickLogoutSource.asObservable();

  // プログラムの更新があった時のイベント
  updateApplicationSource = new Subject<any>();
  updateApplication$ = this.updateApplicationSource.asObservable();

  constructor() {}
}

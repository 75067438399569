import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
/**
 * BLEの標準フォーマットであるGATTに関する処理をまとめたService
 * 
 * URL: https://www.bluetooth.com/specifications/gatt/characteristics/
 */
export class GATTService {
  static characteristic = {
    // バッテリー情報を受信するためのイベント
    // org.bluetooth.characteristic.battery_level
    batteryLevel: {
      service: '180F', // batteryServiceUUID
      characteristic: '2A19', // batteryCharacteristicUUID
    },
    // 体温データを受信するためのイベント
    // org.bluetooth.characteristic.temperature_measurement
    temperatureMeasurement: {
      service: '1809',
      characteristic: '2A1C',
    },
    // 血圧データを受信するためのイベント
    // org.bluetooth.characteristic.blood_pressure_measurement	
    bloodPressureMeasurement: {
      service: '1810',
      characteristic: '2A35',
    },
  }

  constructor() { }

  /**
   * [GAT仕様]BLEデバイスに現在の時間をバイナリデータとして記録させる
   *
   * Bufferを返り血で返す
   */
  static getBinaryDateTime(): ArrayBuffer {
    /**
     *
     *  | Name | Byte | Format | Thermometer |
     *  |:----:|:----:|:----:|:----:|
     *  | Year | 2 | 16bit |  07D0~0833 |
     *  | Month | 1 | 8bit | 01~0C |
     *  | Day | 1 | 8bit | 01~1F |
     *  | Hours | 1 | 8bit | 00~18 |
     *  | Minutes | 1 | 8bit | 00~3B |
     *  | Second | 1 | 8bit | 00~3B |
     */
    // 時間の設定をしてみる！
    const buffer = new ArrayBuffer(7);
    const dv = new DataView(buffer);

    const dt = new Date(); //年・月・日・曜日を取得する
    console.log(dt);
    const year = dt.getFullYear();
    // 2byteで記憶させる際にはリトルエンディアン方式でメモリ上では逆順に保存させる
    dv.setUint16(0, year, true);

    // - 月
    const month = dt.getMonth() + 1;
    dv.setUint8(2, month);
    // - 日
    const day = dt.getDate();
    dv.setUint8(3, day);

    // - 時
    const hour = dt.getHours();
    dv.setUint8(4, hour);
    // - 分
    const minute = dt.getMinutes();
    dv.setUint8(5, minute);
    // - 秒
    const second = dt.getSeconds();
    dv.setUint8(6, second);
    console.log(dv.buffer);

    return dv.buffer;
  }

    /**
   * BLEに設定されている時間情報を読み取ってみる
   * unixTimestamp(ミリ秒表記)で返すようにする
   */
  static readBinaryTimestamp(res: any, littleEndian = true): number {
    const dv = new DataView(res);
    let index = 0;

    let year: number, month: number, day: number, hour: number, minute: number, second: number;
    year = dv.getUint16(index, true);
    index += 2;
    month = dv.getUint8(index);
    index++;
    day = dv.getUint8(index);
    index++;
    hour = dv.getUint8(index);
    index++;
    minute = dv.getUint8(index);
    index++;
    second = dv.getUint8(index);
    const datetime = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
    console.log(datetime);
    return moment(datetime).unix();
  }

  /*
    *************************************
    *  IEEE-11073のSFLOATとFLOAT型を扱う  *
    *************************************
    */

  /**
   * Float型(32bit)をバイナリから取得し実数に変換する
   * - IEEE 11073 の 10を基底とする浮動小数点の扱い
   * 
   * @param dv:DataView   32bitのバイナリデータ
   * @param littleEndian  バイトオーダーの扱い(デフォルトは、fakse = ビッグエンディアン方式：)
   */

  static readFLOAT(dv, littleEndian = false) {

    // 1. バイトオーダーを逆転させて取得する(dataは32bitで取得した値から10進数表記で表示される)
    // 32bitで取得する際の第二引数がlittleEndianかどうかを判定する仕組み
    var data = dv.getUint32(0, littleEndian);

    // 2. 仮数部を取得する(32bitの場合は下位24bitが仮数部)
    // 0x0FFFF(32bitのうち下位24bit分を取得し、取得した値を10進数に直したものを数値型にしたものが仮数部)
    var mantissa = (data & 0x00FFFFFF);

    // 仮数部も2の補数で判定するため32bitのうちの24bit中の上位1桁がマイナスを表していないかを確認
    if ((mantissa & 0x00800000) > 0) {
      // もしマイナス値の場合は、1を引いた後反転処理と24bit分の論理和を取得する)
      mantissa = -1 * (~(mantissa - 0x01) & 0x00FFFFFF)
    }

    // 3. 指数部を取得する(32bitの場合は上位8bitが指数部)
    // 上位8bitを取得するためには32bitの場合右へ24bitシフトさせると取得可能
    // なお、>>だと符号を維持する右シフトであるため、 bit演算を用いると2の補数も考慮して10進数に変換してくれる
    // 符号を考慮しない場合は>>>とすれば良いらしい
    var exponential = data >> 24;

    var result = mantissa * Math.pow(10, exponential);
    return result;
  }


  /**
   * SFLOAT型(16bit)をバイナリから取得し実数に変換する
   * - IEEE 11073 の 10を基底とする浮動小数点の扱い
   * 
   * @param dv:DataView   16bitのバイナリデータ
   * @param littleEndian  バイトオーダーの扱い(デフォルトは、fakse = ビッグエンディアン方式：)
   */
  static readSFLOAT(dv, littleEndian = false) {

    // 1. バイトオーダーを逆転させて取得する(dataは32bitで取得した値から10進数表記で表示される)
    // 32bitで取得する際の第二引数がlittleEndianかどうかを判定する仕組み
    var data = dv.getUint16(0, littleEndian);

    // 2進数で表示してみると: 11100100 0000000
    console.log(data.toString(2));

    // 2. 仮数部を取得する(16bitの場合は下位12bitが仮数部)
    // 0x0FFF(16bitのうち下位12bit分を取得し、取得した値を10進数に直したものを数値型にしたものが仮数部)
    var mantissa = (data & 0x0FFF);

    // 仮数部も2の補数で判定するため16bitのうちの12bit中の上位1桁がマイナスを表していないかを確認
    if ((mantissa & 0x0800) > 0) {
      // もしマイナス値の場合は、1を引いた後反転処理と12bit分の論理和を取得する)
      mantissa = -1 * (~(mantissa - 0x01) & 0x0FFF)
    }

    // 3. 指数部を取得する(16bitの場合は上位4bitが指数部)
    // 上位4bitを取得するためには16bitの場合右へ12bitシフトさせると取得可能
    // なお、>>だと符号を維持する右シフトであるため、 bit演算を用いると2の補数も考慮して10進数に変換してくれる
    // 符号を考慮しない場合は>>>とすれば良いらしい
    var exponential = data >> 12;

    var result = mantissa * Math.pow(10, exponential);
    return result;
  }
  /**
   * BLEに設定されている時間情報を読み取ってみる
   * unixTimestamp(ミリ秒表記)で返すようにする
   */
  static readDateTime(dv, index = 0, littleEndian = false) {
    let year, month, day, hour, minute, second;

    year = dv.getUint16(index, true);
    index += 2;
    month = dv.getUint8(index);
    index++;
    day = dv.getUint8(index);
    index++;
    hour = dv.getUint8(index);
    index++;
    minute = dv.getUint8(index);
    index++;
    second = dv.getUint8(index);

    console.log(year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ':' + second);

    var dt = new Date(year, month - 1, day, hour, minute, second);
    return Math.floor(dt.getTime() / 1000);
  }

}
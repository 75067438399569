import { OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
// import { Loading } from "ionic-angular";
import { ModalController, AlertController, Platform } from '@ionic/angular';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


const WEEK_LIST = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export class AbstractPage implements OnDestroy {

  // loading: Loading;
  onDestroy$ = new Subject();

  constructor() {
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  // closeLoading() {
  //   if (!this.loading) {
  //     return;
  //   }
  //   this.loading.dismiss();
  //   this.loading = null;
  // }
  async showModal(
    modalCtrl: ModalController,
    component: any, componentProps?,
    options: {showBackdrop?: boolean, backdropDismiss?: boolean, cssClass?: string, animated?: boolean} = {}
  ) {
    // const modal = await this.handleShowModal(this.modalCtrl, component, componentProps);
    const modal = await modalCtrl.create({
      component,
      componentProps,
      ...options
      // showBackdrop: true, backdropDismiss: true,
    });
    await modal.present();
    return modal;
  }

  async confirm(text) {
    if (window.confirm(text)) {
      return true;
    };
    return false;
  }

  checkTabletMode(platform: Platform) {
    console.log('Width: ' + platform.width());
    console.log('Height: ' + platform.height());
    return (platform.width() >= 680);
  }

  async sleep(time = 300) {
    await new Promise((resolve) => {
      setTimeout(() => resolve(true), time);
    })
  }

  getWeekList() {
    return WEEK_LIST;
  }

  getWeekName(week) {
    switch (week) {
      case 'sun':
        return "日曜日";
      case 'mon':
        return "月曜日";
      case 'tue':
        return "火曜日";
      case 'wed':
        return "水曜日";
      case 'thu':
        return "木曜日";
      case 'fri':
        return "金曜日";
      case 'sat':
        return "土曜日";
    }
    return "";
  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { VitalModel } from '@srcapp/models/vital.model';
import { VitalService } from '@srcapp/services/vital/vital.service';
import { DeviceService } from '@srcapp/services/device.service';
import { BleDeviceModelType } from '@srcapp/types/bleDeviceModel.typs';
import { PatientInfoType } from '@srcapp/types/apiResponse.type';
import { Router } from '@angular/router';
import { AbstractPage } from './../../../pages/abstract-page';
import { PatientService } from '@srcapp/services/master/patient.service';
import { takeUntil } from 'rxjs/operators';
import { AuthStoreService } from '@srcapp/services/store/auth-store.service';
import * as moment from 'moment';

@Component({
  selector: 'app-multi-vital-measurement-modal',
  templateUrl: './multi-vital-measurement-modal.component.html',
  styleUrls: ['./multi-vital-measurement-modal.component.scss'],
})
export class MultiVitalMeasurementModalComponent  extends AbstractPage implements OnInit {

  // - 当日絞り込みを表示するかどうか
  isAdultDayCare: boolean = false;

  // **** **** ****
  // * 絞り込み
  // **** **** ****
  //- 当日絞り込み
  date: string = moment().format('YYYY-MM-DD');
  targetPatientIdList:{patientId:string}[]|null = null;
  selectedTargetDate:string = this.patientService.selectedTargetDate;
  // - 五十音
  kanaList: any = [];
  selectedKana: string = this.patientService.selectedKana;
  // - ユニット絞り込み
  unitList = [];
  selectedUnit: string = this.patientService.selectedUnit;
  // - 部屋絞り込み
  roomList = [];
  selectedRoom:string =  this.patientService.selectedRoom;



  tab: string = 'patient';
  vitalModel: VitalModel;

  selectedPatient: PatientInfoType | null;
  
  deviceList: BleDeviceModelType[] = [];
  selectedDeviceModel: BleDeviceModelType;


  constructor(
    public router: Router,
    public modalCtrl: ModalController,
    public vitalService: VitalService,
    private deviceService: DeviceService,
    private authStoreService: AuthStoreService,
    public patientService: PatientService, 
  ) {
    super();

    this.patientService.listUnitAndRoom$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.unitList = data.unitList;
      this.roomList = data.roomList;
    });
  }

  async ngOnInit() {
    this.vitalModel = this.vitalService.createVitalMode();

    this.isAdultDayCare = this.authStoreService.isAdultDayCare;

    // ストレージからペアリング済みの情報を読み取る
    this.deviceList = await this.deviceService.getDeviceList();
    console.log(this.deviceList);
  }


  closeEvent(params) {
    console.log(' *** :** closeEvent **: ***');
    console.log(params);
    if (params && params.patient) {
      this.selectedPatient = params.patient;
      this.vitalModel.setPatient(params.patient, false);
      // タブの自動遷移はやめておく
      // this.tab = 'device';  
    }
  }

  closeModel() {
    this.modalCtrl.dismiss();
  }

  save() {

    this.vitalModel.setBleDevice(this.selectedDeviceModel);
    this.vitalModel.isWaitReceiveVital = true;
  
    this.modalCtrl.dismiss({ vitalModel: this.vitalModel});
  }

  select(device: BleDeviceModelType) {
    console.log('選択中');
    console.log(device);
    this.selectedDeviceModel = device;
    // this.modalController.dismiss({staff});
  }

  isSelected(deviceId: string): boolean {
    if (!this.selectedDeviceModel) return false;
    return (this.selectedDeviceModel.deviceId === deviceId);
  }

  changeParingPage() {
    this.modalCtrl.dismiss();
    this.router.navigateByUrl('/setting-ble-device')

  }

}

import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { BLE } from '@ionic-native/ble/ngx';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';

// メーカー別のモデルの共通型定義
import { VitalDataType } from '../../types/vital.type';
import { BleScanDeviceJsonType, BleDeviceModelType  } from '../../types/bleDeviceModel.typs'

// *** メーカー別のデバイスモデルの情報を取得する ***
import { AanddUT201BLEDevice } from './../../models/devices/aanddUT201BLEDevice.model';
import { AanddUA651BLEDevice } from './../../models/devices/aanddUA651BLEDevice.model';
import { OmronTM101B02BLEDevice } from '../../models/devices/omronTM101B02BLEDevice.model';
import { OmronHCR7501TBLEDevice } from '../../models/devices/omronHCR7501TBLEDevice.model';
import { OmonHEM6233TBLEDevice } from './../../models/devices/omonHEM6233TBLEDevice.model';
import { take } from 'rxjs/operators';
import { DEVICE_TYPES } from '@srcapp/models/devices/abstractDevice.model';

/**
 * 本プロジェクトで扱い可能なBLEのデバイス情報を定義しておく
 */
const ALLOW_BLE_DEVICE_NAME_LIST = {
  'A&D_UT201BLE': AanddUT201BLEDevice, // A&Dの体温計
  'A&D_UA-651BLE': AanddUA651BLEDevice, // A&Dの血圧計 : A&D_UA-651BLE_005D18
  '101B-': OmronTM101B02BLEDevice, // OMRONの体温計
  'HEM-6233T': OmonHEM6233TBLEDevice, // OMRONの血圧計
  'HCR-7501T': OmronHCR7501TBLEDevice, // OMRONの血圧計
  'BLEsmart_': OmonHEM6233TBLEDevice, // OMRONの血圧計: GalasyだとBLEsmart
  'BLESmart_': OmonHEM6233TBLEDevice, // OMRONの血圧計: HuwaiだとBLESmart なぜ・・・？
  // ペアリング時: BLEsmart_、アドバタイジング時: BLESmart_
  // 'GT-1830': null, // アークレイ 血糖値計測器
};

@Injectable({
  providedIn: 'root'
})
export class BleService {
  /**
   * BLEのScanが開始されたときの通知
   */
  startScanStatusSource = new Subject<any>();
  startScanStatus$ = this.startScanStatusSource.asObservable();

  /**
   * BLEのSCANが停止されたときの通知
   */
  stopScanStatusSource = new Subject<any>();
  stopScanStatus$ = this.stopScanStatusSource.asObservable();

  /**
   * BLEのSCAN状態が変わったときに通知される
   */
  // private changeStanStatusSource = new Subject<any>();
  // private changeStanStatus$ = this.changeStanStatusSource.asObservable();

  /**
   * BLEデバイスが発見されたときの処理
   */
  discoverSource = new Subject<BleDeviceModelType>();
  discover$ = this.discoverSource.asObservable();

  /**
   * ペアリング処理が開始された時
   */
  startParingSource = new Subject<BleDeviceModelType>();
  startParing$ = this.startParingSource.asObservable();
  endParingSource = new Subject<BleDeviceModelType>();
  endParing$ = this.endParingSource.asObservable();

  /**
   * BLEデバイスからデータを取得した際に呼び出される処理
   */
  // TODO: 型定義は後ほど実施
  receivedDataSource = new Subject<VitalDataType[]>();
  receivedData$ = this.receivedDataSource.asObservable();


  /**
   * BLEのスキャンが実施中かどうか
   */
  public isBleScan: boolean = false;

  /**
   * デバイスに接続中かを判定する
   */
  public isConnecting: boolean = false;

  /**
   * 接続待ちのデバイスリスト
   */
  private waitDeviceList:BleDeviceModelType [] = [];

  /**
   * デバイスに接続した時間
   * タイムアウト判定に利用する予定
   */
  private connectTime: number = 0;
  discoverSubscribe: Subscription;
  paringDiscoverSubscribe: Subscription;
  startScanSubscribe: Subscription;



  /**
   *
   * @param ble
   * @param platform
   */
  constructor(private ble: BLE, private platform: Platform,    public toastCtrl: ToastController) {}

  /**
   * CDCで扱うBLEデバイスのモデルを生成して返す
   * (モデルは、メーカー毎に定義されている)
   *
   * ここに入ってくる処理は、事前にCDCで扱うことが出来るのかのフィルタリングされたものがここに来る
   * そのため、deviceNameだけでなく、serviceなどの情報を利用して、メーカーや機種を特定する
   *
   * @param device BleScanDeviceJsonType
   * @param params {name: string, labelId: string}
   */
  public getDeviceModel(device: BleScanDeviceJsonType, params: { name: string, labelId: string } = null): BleDeviceModelType | null {
    // 1. デバイス名でフィルタリングを行いモデルを返す
    const deviceModel = this._getDeviceModelForDeviceName(device, params);
    if (deviceModel) {
      return deviceModel;
    }

    // 2. serviceなどの情報からフィルタリングを行い、モデルを返す
    return null;
  }


  private _getDeviceModelForDeviceName(device: BleScanDeviceJsonType, params: { name: string, labelId: string } = null): BleDeviceModelType | null {
    const deviceName = device.name || '';
    if (!deviceName) return null;
    // TODO: nameが完全一致であればhash検索でも良いとは思う
    const label = Object.keys(ALLOW_BLE_DEVICE_NAME_LIST).find(key => {
      return deviceName.includes(key);
    });
    if (!label) return null;
    return new ALLOW_BLE_DEVICE_NAME_LIST[label](this.ble, device, params);
  }

  public async stop() {
    console.log('BleService.Stop()');
    if (this.platform.is('cordova')) {
      await this.ble.stopScan();
    }
    // BLEのSCANを停止
    if (this.isBleScan) {
      // スキャン実行中の状態から停止した場合のみ通知をだす
      this.stopScanStatusSource.next();
    }
    if (this.discoverSubscribe) {
      this.discoverSubscribe.unsubscribe();
      this.discoverSubscribe = null;
    }
    if (this.paringDiscoverSubscribe) {
      this.paringDiscoverSubscribe.unsubscribe();
      this.paringDiscoverSubscribe = null;
    }
    this.isBleScan = false;
  }

  // *******************************
  // * 計測機器に接続できるのは、1台ずつのため
  // * 1台ずつ接続する処理を行う
  // *******************************
  /**
   * ペアリング済みのデバイスを周囲で検索し、
   * 発見すれば値や計測結果を取得して返す
   */
  deviceModelList: BleDeviceModelType[] = []
  public async startScanAndGetData(deviceModelList: BleDeviceModelType[] = []) {
    this.deviceModelList = deviceModelList;

    console.log('BleService.startScanAndGetData()');
    // 既にSCAN中である可能性もあるため、一度SCANを止めてから再実行する
    await this.stop();

    if (this.discoverSubscribe) {
      this.discoverSubscribe.unsubscribe();
    }

    // BLEがONになっているかを確認する
    const result = await this.ble.isEnabled().then(() => true).catch(() => false);
    if (!result) return false;

    this.start();
    return true;
  }


  public async start() {
    // BLEのSCANの開始を通知
    if (!this.isBleScan) {
      // スキャン停止中の状態から開始した場合のみ通知をだす
      this.startScanStatusSource.next();
    }
    this.isBleScan = true;
    const services = [];
    // const services = ['1809', '18A0']; // TODO: '1809' and '18A0'

    if (this.startScanSubscribe) {
      this.startScanSubscribe.unsubscribe();
    }

    console.log(this.deviceModelList);
    console.log(`ble.startScan([${services}])`);
    // this.startScanSubscribe = this.ble.startScan(services).subscribe(async(device: BleScanDeviceJsonType) => {
    this.startScanSubscribe = this.ble.startScanWithOptions(services, { reportDuplicates: true }).subscribe(async(device: BleScanDeviceJsonType) => {
      console.log('BLE SCAN', device);
      const model = this.deviceModelList.find(_model => _model.deviceId === device.id);
      if (!model) {
        return ;
      }
      console.log('接続可能な端末=', model);

      // OMRON社の体温計は、コネクションを貼ってNotificationの受付が必要
      if (model.makerCode === 'OMRON' && model.deviceType === DEVICE_TYPES.THE) {
        this.setOmronDevice(model);
      } else {
        // 対象の機器なのでレスポンスを返す
        this.setWaitingDevice(model);
      }
    });
  }


  connectInfo: { [deviceId: string]: number} = {};


  /**
   * 接続待ちリストにデバイスを追加
   */
  async setWaitingDevice(device: BleDeviceModelType) {
    // MEMO: 独自実装からOMRON社のライブラリの組み込みに切り替えたところ、問題なく動作している気がする。
    //       とはいえ、念のため、この処理は残しておく
    // 同一機種のデータは、10秒以上経過しないと再受信出来ないようにする(暫定対応)
    // - OMRONさんの機器が、データ取得後も電波を発信しているため、何度もデータが受信する処理が実行される
    // - アプリでデータ受信後、血圧計からは送らないようにしたいが、そうはいかないみたい...
    if (this.connectInfo[device.deviceId]) {
      const latestConnectTime = moment.unix(this.connectInfo[device.deviceId]);
      const currentTime = moment();
      console.log('接続時間を確認:', currentTime.diff(latestConnectTime, 'second'));
      if (currentTime.diff(latestConnectTime, 'second') < 10) {
        return ;
      }
    }
    this.connectInfo[device.deviceId] = moment().unix();

    this.waitDeviceList.push(device);
    await this.stop();
    await this.sleep();


    this.connectDevice();
  }

  /**
   * 接続中のデバイスがなければ接続処理を行う
   */
  private async connectDevice() {
    if (this.isConnecting) {
      console.log('すでに接続中のため利用できません');
      return ;
    }
    this.isConnecting = true;
    const device = this.waitDeviceList.pop();
    this.connectTime = moment().unix();

    console.log('[BLE,connect]', `** 端末への接続処理開始 **`, `${device.deviceId}`);

    // タイムアウトを一旦10秒とし、強制的に接続を解除する
    const timerId = setTimeout(() => {
      if (device) {
        device.disconnect();
        this.isConnecting = false;
      }
    }, 10 * 1000);

    const dataList = await device.getData().catch(async (e) => {
      console.error('[BLEデータ取得]', 'BLE機器からデータを取得できませんでした', `${device.deviceId}`);
      console.log(e);
      return null;
    });
    this.isConnecting = false;
    clearInterval(timerId);

    console.log('[BLE,connect]', `取得データ=`, `${device.deviceId}`, dataList);

    // データある場合はストリームでデータを流す
    if (dataList && dataList.length > 0) {
      // await this.sleep();
      this.receivedDataSource.next(dataList);
    } else {
      // const toast = await this.toastCtrl.create({
      //   message: '[取得失敗] 計測機器からデータを取得できませんでした',
      //   duration: 10*1000
      // });
      // toast.present();
      // 接続エラーの場合は、数秒で再接続を可能にしておく
      this.connectInfo[device.deviceId] -= 15;
    }

    // アプリが落ちる問題があるためキャッシュクリアメソッドは利用しないこと
    // await this.ble.refreshDeviceCache(device.deviceId, 200).catch(e => console.log(e));

    await this.sleep();

    // 接続待ちのデバイスがある場合は次の処理を行う
    if (this.waitDeviceList.length > 0) {
      this.connectDevice();
    } else {
      // ない場合は、再度計測を開始する
      this.start();
    }
  }

  async sleep(time = 300) {
    await new Promise((resolve) => {
      setTimeout(() => resolve(null), time);
    })
  }

  receivedDevices: {} = {};
  async setOmronDevice(device: BleDeviceModelType) {
    if (this.receivedDevices[device.deviceId]) return;
    this.receivedDevices[device.deviceId] = device;

    // subscribeを受け付けておく
    this.receivedDevices[device.deviceId] = device.vitals$.subscribe(async (dataList) => {
      console.log('[BLE,connect]', `取得データ=`, `${device.deviceId}`, dataList);
      // データある場合はストリームでデータを流す
      if (dataList && dataList.length > 0) {
        await this.sleep();
        this.receivedDataSource.next(dataList);
      }
    });

    const msgSbj = device.connectDevice$.subscribe(async (msg) => {
      if (!msg) return ;
      const toast = await this.toastCtrl.create({
        message: msg,
        duration: 2000,
        // color: this.isDayMode ? '' : 'dark',
      });
      toast.present();

    });
    device.getData().then(async () => {
      if (this.receivedDevices[device.deviceId]) {
        this.receivedDevices[device.deviceId].unsubscribe();
      }
      delete this.receivedDevices[device.deviceId];
      if (msgSbj) msgSbj.unsubscribe();
      await this.stop();
      await this.start();
    }).catch(async () => {
      if (this.receivedDevices[device.deviceId]) {
        this.receivedDevices[device.deviceId].unsubscribe();
      }
      delete this.receivedDevices[device.deviceId];
      if (msgSbj) msgSbj.unsubscribe();
      await this.stop();
      await this.start();
    });
  }

}

//httpConfig.interceptor.ts
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { AuthStoreService } from './services/store/auth-store.service';
import { version, smallVersion } from '../../package.json';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  loaderToShow: any;
  constructor(
    public loadingController: LoadingController,
    public authStoreService: AuthStoreService
  ) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.authStoreService.token;
    const uuid = this.authStoreService.uuid;
    // console.log(token);

    //Authentication by setting header with token value
    if (token) {
      request = request.clone({
        setHeaders: {
          'token': token
        }
      });
    }
    if (uuid) {
      request = request.clone({
        setHeaders: {
          'app-uuid': uuid
        }
      });
    }
  
    if (!request.headers.has('platform')) {
      request = request.clone({
        setHeaders: {
          'platform': 'android'
        }
      });
    }
    // multipart/form-data で送付するときに明示的にContent−Typeを指定するとboundaryが挿入されない
    // - 指定した場合
    //   Content-Type: multipart/form-data; 
    // - 指定しなかった場合
    //   Content-Type: multipart/form-data; boundary=----WebKitFormBoundary5z75Bj4L6Dfk1lZs
    //
    // boundaryが無いと、サーバ側ではバイナリデータの区切りが分からずにリクエストエラーとなっていたため、
    // ここで明示的にapplication/jsonと定義するのではなく、型推論に任せることにする
    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({
    //     setHeaders: {
    //       'content-type': 'application/json'
    //     }
    //   });
    // }
    if (!request.headers.has('current-app-version')) {
      request = request.clone({
        setHeaders: {
          'current-app-version': version,
          'current-app-small-version': smallVersion,
        }
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });
    console.log(request.headers);
    this.showLoader();
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event--->>>', event);
        }
        this.hideLoader();
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.hideLoader();
        return throwError(error);
      }));
  }

  showLoader() {
    // this.loaderToShow = this.loadingController.create({
    //   message: 'Processing Server Request'
    // }).then((res) => {
    //   res.present();

    //   res.onDidDismiss().then((dis) => {
    //     console.log('Loading dismissed!');
    //   });
    // });
    // this.hideLoader();
  }

  hideLoader() {
      // this.loadingController.dismiss();
  }


}
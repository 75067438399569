import { AlertController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { CalculatorService } from '@srcapp/services/vital/calculator.service';
import { VITAL_TYPES } from '@srcapp/config/vital.config';
import { VitalDataType } from '@srcapp/types/vital.type';
import { takeUntil } from 'rxjs/operators';
import { AbstractPage } from '@srcapp/pages/abstract-page';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent extends AbstractPage implements OnInit {

  @Input() vitalType: VITAL_TYPES = null;
  @Input() vitalData: VitalDataType = null;
  @Input() isModal: boolean = false;

  constructor(
    public calculatorService: CalculatorService,
    public alertCtrl: AlertController
  ) {
    super();
  }

  ngOnInit() {
    if (this.vitalType) {
      this.calculatorService.setVitalData(this.vitalType, this.vitalData);
    }
  }

  ngOnChanges(event) {
    if (this.vitalType) {
      this.calculatorService.setVitalData(this.vitalType, this.vitalData);
    }

  }

  /**
   * 電卓を閉じようとしたときの処理
   */
  async closeCalculator() {
    // エラーメッセージなどもcloseメソッドに任せる
    this.calculatorService.close();
  }



}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiPatientService } from '@srcapp/services/api/api-patient.service'
import { PatientInfoType } from '@srcapp/types/apiResponse.type';
import * as moment from 'moment';

let CACHE_PATIENT_LIST = [];
let CACHE_TODAY_PATIENT_LIST = [];

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  // 設定した内容を記憶しておく
  selectedTargetDate: string = 'all';
  selectedSchedule: string = 'all';
  selectedKana: string = 'all';
  selectedWard: string = 'all';
  selectedUnit: string = 'all';
  selectedRoom: string = 'all';
  // 検索条件でのキャッシュ
  queryCacheTable: {
    [queryKey: string]: {
      data: any;
      time: number;
    }
  } = {};

  // - ユニット絞り込み
  unitList = [];
  // - 部屋絞り込み
  roomList = [];

  // APIのキャッシュ時間
  cacheTimePatientList = 0;
  cacheTimeTodayPatient = 0;

  public listSource = new Subject<any>();
  public list$ = this.listSource.asObservable();

  public listUnitAndRoomSource = new Subject<{wardGroup: any, unitGroup: any, roomGroup: any, wardList: any[], unitList: any[], roomList: any[]}>();
  public listUnitAndRoom$ = this.listUnitAndRoomSource.asObservable();

  constructor(public apiPatientService: ApiPatientService) {}

  clearCache() {
    CACHE_PATIENT_LIST = [];
    this.cacheTimePatientList = 0;
    CACHE_TODAY_PATIENT_LIST = [];
    this.cacheTimeTodayPatient = 0;
    this.queryCacheTable = {};
  }

  async getList(): Promise<PatientInfoType[]> {
    const timestamp = moment().unix();

    // 5分間は、キャッシュを保持する
    if (CACHE_PATIENT_LIST.length > 0 && (this.cacheTimePatientList + 300) > timestamp) {
      return CACHE_PATIENT_LIST;
    }
    const data = await this.apiPatientService.getList();
    this.cacheTimePatientList = timestamp;
    CACHE_PATIENT_LIST = (data) ? data.patientList : [];
    return CACHE_PATIENT_LIST;
  }

  async searchPatient(query:any = {}): Promise<PatientInfoType[]> {
    const timestamp = moment().unix();
    // 検索条件オブジェクトからキャッシュ用文字列を生成する
    const cacheKey = this._makeQueryCacheKey(query);
    // 5分間は、キャッシュを保持する
    // if (this.queryCacheTable.hasOwnProperty(cacheKey) && this.queryCacheTable[cacheKey].time + 300 > timestamp) {
    //   return this.queryCacheTable[cacheKey].data;
    // }
    const data = await this.apiPatientService.search(query);
    this.queryCacheTable[cacheKey] = {
      time: timestamp,
      data: (data && data.patientList) ? data.patientList : [],
    }
    return this.queryCacheTable[cacheKey].data;
  }

  async getById(patientId: string): Promise<PatientInfoType> {
    const list = await this.getList();
    console.log(list)
    return list.find((p) => {
      return p.patientId === patientId;
    });
  }

  /**
   * ユニット&ルーム情報をリスト化する
   * @param patients
   * @param unitGroup
   * @param roomGroup
   * @param unitList
   * @param roomList
   */
  listUnitAndRoom(patients): void {

    let wardGroup = {};
    let unitGroup = {};
    let roomGroup = {};

    if (!patients) {
      this.listUnitAndRoomSource.next({
        wardGroup: wardGroup,
        unitGroup: unitGroup,
        roomGroup: roomGroup,
        wardList: [],
        unitList: [],
        roomList: [],
      });
      return ;
    }

    patients.forEach((_patient: any) => {
      if (_patient.wardName) {
        wardGroup[_patient.wardName] = { wardName: _patient.wardName, isShow: true };
      }
      if (_patient.unitName) {
        unitGroup[_patient.unitName] = { unitName: _patient.unitName, isShow: true };
      }
      if (_patient.roomName) {
        roomGroup[_patient.roomName] = { roomName: _patient.roomName, isShow: true };
      }
    });
    let wardList = Object.keys(wardGroup).map((wardKey) => { return wardGroup[wardKey]; }).sort((a, b) => a.wardName > b.wardName ? 1 : -1);
    let unitList = Object.keys(unitGroup).map((unitKey) => { return unitGroup[unitKey]; }).sort((a, b) => a.unitName > b.unitName?1:-1);
    let roomList = Object.keys(roomGroup).map((roomKey) => { return roomGroup[roomKey]; }).sort((a, b) => a.roomName > b.roomName?1:-1);

    this.listUnitAndRoomSource.next({
      wardGroup: wardGroup,
      unitGroup: unitGroup,
      roomGroup: roomGroup,
      wardList: wardList,
      unitList: unitList,
      roomList: roomList,
    });
  }

  filterList(patientList, selectedWard, selectedUnit, selectedRoom, selectedKana = 'all', targetPatientIdList = null) {
    // - フロアーの処理

    // TODO: パラメータで受け取る
    if (selectedWard !== 'all') {
      patientList = patientList.filter(_data => {
        return (_data.wardName === selectedWard);
      });
    }

    // - グループの処理
    if (selectedUnit !== 'all') {
      patientList = patientList.filter(_data => {
        return (_data.unitName === selectedUnit);
      });
    }
    // - 部屋の処理
    if (selectedRoom !== 'all') {
      patientList = patientList.filter(_data => {
        return (_data.roomName === selectedRoom);
      });
    }

    // - あいうえお絞り込み
    if (selectedKana !== 'all') {
      patientList = this.filterByKana(patientList, selectedKana);
    }

    // - 特定の利用者IDで絞り込む
    if (targetPatientIdList && Array.isArray(targetPatientIdList)) {
      let indexIds:any = {};
      targetPatientIdList.forEach(p => indexIds[p.patientId] = true);
      patientList = patientList.filter(_data => {
                      return (indexIds[_data.patientId])?true:false;
                    });
    }

    return patientList;
  }

  async checkSelect(patientList, selectPatientId) {
    let patient = patientList.find(_p => {
      return _p.patientId === selectPatientId;
    });
    if (!patient) {
      alert('利用者が選択されていません');
      return;
    }
    return Promise.resolve(patient)
  }


  filterByKana(patientList, kana) {
    let list = FILTER_KANA_CONFIG.FILTER[kana];
    if (list) {
      patientList = patientList.filter(_data => {
                      return list.find(_strMatch => {
                        if (_data.nameKana.match(_strMatch)) {
                          return true;
                        }
                        return false;
                      });
                    });
    } else {
      // confがない場合は、上記式に当てはまらないデータを抽出することになる
      let filterList = [];
      Object.keys(FILTER_KANA_CONFIG.FILTER).forEach(key => {
        filterList = filterList.concat(FILTER_KANA_CONFIG.FILTER[key]);
      });
      patientList = patientList.filter(_data => {
                      let result = filterList.find(_strMatch => {
                        if (_data.nameKana.match(_strMatch)) {
                          return true;
                        }
                        return false;
                      });
                      return !result;
                    });
    }
    return patientList;
  }

  async create(patient: Partial<PatientInfoType>): Promise<boolean> {
    const result = await this.apiPatientService.create(patient);
    if (result.msg === 'success.') {
      this.clearCache();
    }
    return (result.msg === 'success.');
  }

  async update(patient: PatientInfoType): Promise<boolean> {
    if (!patient.patientId) {
      return false;
    }
    const result = await this.apiPatientService.update(patient);
    if (result.msg === 'success.') {
      this.clearCache();
    }
    return (result.msg === 'success.');
  }

  async deleteById(patientId): Promise<boolean> {
    const result = await this.apiPatientService.deleteById(patientId);
    if (result.msg === 'success.') {
      this.clearCache();
    }
    return (result.msg === 'success.');
  }

  // アルファベット順にキーをソートしたjson文字列を作成する
  _makeQueryCacheKey(query: object) {
    // https://stackoverflow.com/questions/16167581/sort-object-properties-and-json-stringify
    // console.log(this._flattenObj(query))
    // console.log(Object.keys(this._flattenObj(query)))
    // console.log(Object.keys(this._flattenObj(query)).sort());
    const flattenQuery = this._flattenObj(query);
    return JSON.stringify(flattenQuery, Object.keys(flattenQuery).sort());
  }
  // オブジェクトをflattenする
  _flattenObj(obj) {
    const toReturn = {};
    for (const i in obj) {
      if (!obj.hasOwnProperty(i)) continue;
      // 再帰
      if ((typeof obj[i]) == 'object') {
        const flatObject = this._flattenObj(obj[i]);
        for (const x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;
          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = obj[i];
      }
      // console.log(obj[i])
      // console.log(toReturn)
    }
    return toReturn;
  }
}


export class FILTER_KANA_CONFIG {
  static readonly FILTER = {
    "あ": [
      /^[\u3041-\u304A]/g, // - あ行
      /^[\u30A1-\u30AA]/g, // - ア行
      /^[\uFF67-\uFF75]/g  // - ｱ行
    ],
    "か": [
      /^[\u304B-\u3054]/g, // - か行
      /^[\u30AB-\u30B2]/g, // - カ行
      /^[\uFF76-\uFF7A]/g, // - ｶ行
    ],
    "さ": [
      /^[\u3055-\u305E]/g,  // - さ行
      /^[\u30B5-\u30BD]/g,  // - サ行
      /^[\uFF7B-\uFF7F]/g,  // - ｻ行
    ],
    "た": [
      /^[\u305F-\u3069]/g,  // - た行
      /^[\u30BF-\u30C9]/g,  // - タ行
      /^[\uFF80-\uFF84]/g,  // - ﾀ行
    ],
    "な": [
      /^[\u306A-\u306E]/g,  // - な行
      /^[\u30CA-\u30CE]/g,  // - ナ行
      /^[\uFF85-\uFF89]/g,  // - ﾅ行
    ],
    "は": [
      /^[\u306F-\u307D]/g,  // - は行
      /^[\u30CF-\u30DD]/g,  // - ハ行
      /^[\uFF8A-\uFF8E]/g,  // - ﾊ行
    ],
    "ま": [
      /^[\u307E-\u3082]/g,  // - ま行
      /^[\u30DE-\u30E2]/g,  // - マ行
      /^[\uFF8F-\uFF93]/g,  // - ﾏ行
    ],
    "や": [
      /^[\u3083-\u3088]/g,  // - や行
      /^[\u30E3-\u30E8]/g,  // - ヤ行
      /^[\uFF6C-\uFF6E]/g,  // - ﾔ行
      /^[\uFF94-\uFF96]/g,  // - ﾔ行
    ],
    "ら": [
      /^[\u3089-\u308D]/g,  // - ら行
      /^[\u30E9-\u30ED]/g,  // - ラ行
      /^[\uFF97-\uFF9B]/g,  // - ﾗ行
    ],
    "わ": [
      /^[\u308f-\u3093]/g,  // - わ行
      /^[\u30EE-\u30F3]/g,  // - ワ行
      /^[\uFF9C-\uFF9D]/g,  // - ﾜ行
    ]
  };
}

import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule }   from '@angular/forms';

import { PipesModule } from '@srcapp/pipes/pipes.module';

// Modal描画用のComponent
import { BleParingModalPage } from '@srcapp/components/modals/ble-paring-modal/ble-paring-modal.page';
import { PatientListModalComponent } from '@srcapp/components/modals/patient-list-modal/patient-list-modal.component';
import { StaffListModalComponent } from '@srcapp/components/modals/staff-list-modal/staff-list-modal.component';
import { VitalEditModalComponent } from '@srcapp/components/modals/vital-edit-modal/vital-edit-modal.component';
import { CalculatorModalComponent } from '@srcapp/components/modals/calculator-modal/calculator-modal.component';
import { NewsDetailModalPageComponent } from '@srcapp/components/modals/news-detail-modal-page/news-detail-modal-page.component';
import { MultiVitalMeasurementModalComponent } from '@srcapp/components/modals/multi-vital-measurement-modal/multi-vital-measurement-modal.component';
import { PatientMultiSelectListModalComponent } from '@srcapp/components/modals/patient-multi-select-list-modal/patient-multi-select-list-modal.component';
import { DeviceSelectModalComponent } from '@srcapp/components/modals/device-select-modal/device-select-modal.component';
// popover
 import { CommentListPopoverComponent } from '@srcapp/components/popovers/comment-list-popover/comment-list-popover.component';
// Component
import { CalculatorComponent } from '@srcapp/components/calculator/calculator.component';
import { InputVitalComponent } from '@srcapp/components/input-vital/input-vital.component';
import { PatientListComponent } from '@srcapp/components/patient-list/patient-list.component';
import { PatientMultiSelectListComponent } from '@srcapp/components/patient-multi-select-list/patient-multi-select-list.component';
import { VitalChartComponent } from '@srcapp/components/vital-chart/vital-chart.component';

@NgModule({
  imports: [CommonModule, IonicModule, PipesModule, FormsModule],
  declarations: [
    CalculatorComponent,
    InputVitalComponent,
    PatientListComponent,
    VitalChartComponent,
    BleParingModalPage,
    PatientListModalComponent,
    StaffListModalComponent,
    VitalEditModalComponent,
    CalculatorModalComponent,
    NewsDetailModalPageComponent,
    MultiVitalMeasurementModalComponent,
    PatientMultiSelectListModalComponent,
    PatientMultiSelectListComponent,
    DeviceSelectModalComponent,
    CommentListPopoverComponent,
  ],
  exports: [
    CalculatorComponent,
    InputVitalComponent,
    PatientListComponent,
    VitalChartComponent,
    BleParingModalPage,
    PatientListModalComponent,
    StaffListModalComponent,
    CalculatorModalComponent,
    NewsDetailModalPageComponent,
    MultiVitalMeasurementModalComponent,
    PatientMultiSelectListModalComponent,
    PatientMultiSelectListComponent,
    DeviceSelectModalComponent,
    CommentListPopoverComponent,
  ],
  entryComponents:[
    CalculatorComponent,
    InputVitalComponent,
    PatientListComponent,
    VitalChartComponent,
    BleParingModalPage,
    PatientListModalComponent,
    StaffListModalComponent,
    VitalEditModalComponent,
    CalculatorModalComponent,
    NewsDetailModalPageComponent,
    MultiVitalMeasurementModalComponent,
    PatientMultiSelectListModalComponent,
    PatientMultiSelectListComponent,
    DeviceSelectModalComponent,
    CommentListPopoverComponent,
  ]
   
})
export class MyCommonModule {}

import { NgModule } from '@angular/core';
import { DatePipe } from './date.pipe';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [DatePipe],
  exports: [DatePipe],
})
export class PipesModule {}

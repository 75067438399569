import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({
  name: 'datePipe'
})
export class DatePipe implements PipeTransform {

  transform(value: number, format: string): unknown {
    if (!value) return '';
    return moment.unix(value).format(format);
  }


}

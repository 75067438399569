import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AuthStoreService } from './../services/store/auth-store.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public authStoreService: AuthStoreService, public router: Router, public platfor: Platform) {

  }
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
      await this.authStoreService.waitInitialize;


      if (!this.checkSupportBrowser()) {
        alert('Google Chrome (https://www.google.com/intl/ja_jp/chrome/) のブラウザを利用して、アクセスして下さい')
        return false;
      }


      let isLicenseAuth = this.authStoreService.isLicenseAuth(); // Get the current authentication state from a Service!
      if (!isLicenseAuth) {
        this.router.navigate(['/license-key']);
        return false;
      }

      let isAuth = this.authStoreService.isAuth(); // Get the current authentication state from a Service!
      console.log('canActivate', isAuth);
      if (!isAuth) {
        this.router.navigate(['/login']);
        return false;
      }
      return true;
  }


  private checkSupportBrowser(): boolean {
    if (this.platfor.is('cordova')) return true;

    let isOk = false;
    const userAgent = window.navigator.userAgent.toLowerCase();
    if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
      console.log('Internet Explorerをお使いですね');
    } else if(userAgent.indexOf('edge') != -1) {
      console.log('Edgeをお使いですね');
    } else if(userAgent.indexOf('chrome') != -1) {
      console.log('Google Chromeをお使いですね');
      isOk = true;
    } else if(userAgent.indexOf('safari') != -1) {
      // SafariもOKとしておく
      console.log('Safariをお使いですね');
      isOk = true;
    } else if(userAgent.indexOf('firefox') != -1) {
      console.log('FireFoxをお使いですね');
    } else if(userAgent.indexOf('opera') != -1) {
      console.log('Operaをお使いですね');
    } else {
      console.log('そんなブラウザは知らん');
    }
    return isOk;
  }




  
}

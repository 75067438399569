import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Subject } from 'rxjs';
import { VitalJsonType, VitalDataType, TagType } from '@srcapp/types/vital.type';
import { VitalModel } from '@srcapp/models/vital.model';
import { VITAL_CONFIG, VITAL_TYPES } from '@srcapp/config/vital.config';
import { Events } from '@srcapp/services/events.servce';
import { ApiVitalService } from '@srcapp/services/api/api-vital.service';
import { AuthStoreService } from '@srcapp/services/store/auth-store.service';

const STORAGE_UNSENT_NAME = 'storage.unsent.vital';
@Injectable({
  providedIn: 'root'
})
export class VitalService {

  updateVitalDataSource = new Subject<VitalDataType[]>();
  updateVitalData$ = this.updateVitalDataSource.asObservable();

  unsentVitalList: VitalModel[] = [];

  public errorMessage;

  constructor(
    private storage: Storage,
    private events: Events,
    private apiVitalService: ApiVitalService,
    private authStoreService: AuthStoreService
  ) {
    this.events.subscribe('event.update.vital', (vitalModel: VitalModel) => {
      this.saveUnsentVitalList(vitalModel);
    });
    this.getUnsentVitalList();
  }

  /**
   * バイタルモデルを取得する
   */
  createVitalMode(): VitalModel {
    const model = new VitalModel(this.events, this.authStoreService.contractId);
    const staff = this.authStoreService.userInfo;
    model.data.staffId = staff.staffId;
    model.data.staffName = staff.staffName;
    return model;
  }

  /**
   * logId を元に未送信バイタルデータを特定する
   *
   */
  findByLogId(logId: string) {
    return this.unsentVitalList.find((v) => logId === v.logId);
  }

  async getUnsentVitalList(): Promise<VitalModel[]> {
    if (this.unsentVitalList.length === 0) {
        let data: VitalJsonType[] | null = await this.storage.get(STORAGE_UNSENT_NAME);
        this.unsentVitalList = (data || []).map((json) => {
          if (!json.vitals) return null;

          const result = Object.keys(json.vitals).find(key => {
                          return (json.vitals[key] && json.vitals[key].value);
                        });
          if (!result) return null;
          const model = new VitalModel(this.events, '');
          model.importJson(json);
          return model;
        }).filter(data => data);
    }
    return this.unsentVitalList;
  }

  /**
   * バイタルデータをストレージに保存する
   *
   */
  async saveUnsentVitalList(vitalModel: VitalModel) {
    // バイタルを保存するときは、いずれかのバイタル値が設定されていることが条件
    if (!vitalModel.isExistVitalData()) return ;


    let isNew = true;
    const unsentVitalList = this.unsentVitalList.map(vital => {
      if (vital.logId === vitalModel.logId) {
        isNew = false;
        return vitalModel;
      }
      return vital;
    });
    if (isNew) {
      unsentVitalList.push(vitalModel);
    }
    this.unsentVitalList = unsentVitalList;

    // 出力してストレージに保存する
    const json = this.unsentVitalList.map(vital => vital.exportJson());
    await this.storage.set(STORAGE_UNSENT_NAME, json);
  }

  /**
   * ストレージからバイタルデータを削除する
   *
   * @param vitalModel
   */
  async deleteUnsentVitalList(vitalModel: VitalModel) {
    const unsentVitalList = this.unsentVitalList.filter(vital => {
      if (vital.logId === vitalModel.logId) {
        return false;
      }
      return true;
    });
    this.unsentVitalList = unsentVitalList;
    // 出力してストレージに保存する
    const json = this.unsentVitalList.map(vital => vital.exportJson());
    await this.storage.set(STORAGE_UNSENT_NAME, json);
  }

  /**
   * ストレージから全てのバイタルデータを削除する
   */
  async deleteAllUnsentVitalList() {
    this.unsentVitalList = [];
    // 出力してストレージに保存する
    await this.storage.set(STORAGE_UNSENT_NAME, []);
  }


  /**
   * 設定によって計測可能なバイタルの種類や表示順番は変更可能にしておく
   */
  async getVitalTypeList() {
    // TODO: 現状は固定
    return [
      // 体温
      VITAL_TYPES.BODY_TEMPERATURE,
      // 血圧（上)
      VITAL_TYPES.BLOOD_PRESSURE_UP,
      // 血圧(下) リストで返すときはどちらかの値だけでよい
      // VITAL_TYPES.BLOOD_PRESSURE_DOWN
      // 脈拍数
      VITAL_TYPES.PLUSE,
      // SpO2
      VITAL_TYPES.SPO2,
      // 呼吸数
      VITAL_TYPES.RESPIRATION,
      // 血糖値
      VITAL_TYPES.BLOOD_GLUCOSE_LEVEL,
      // 身長
      VITAL_TYPES.BODY_HEIGHT,
      // 体重
      VITAL_TYPES.BODY_WEIGHT,
    ];
  }


  /**
   * バイタルデータをAPIで送信s塗る
   */
  async sendVital(postData: VitalJsonType) {
    return this.apiVitalService.sendVital(postData).then((res) => {
      this.errorMessage = this.apiVitalService.errorMessage;
      return res;
    });
  }
  /**
   * クラウドに保存されているバイタルデータをAPIで更新する
   * @param vitalId
   * @param postData
   */
  async updateCloudVital(vitalId: string, postData: VitalJsonType) {
    return this.apiVitalService.updateCloudVital(vitalId, postData).then((res) => {
      return res;
    });
  }

  /**
   * 指定のtypeのバイタルを、期間指定で取得するAPI
   */
  async getVitalList(_id: string, start: number, end: number, types: string[]): Promise<any> {
    return this.apiVitalService
      .getPersonalVitalBetween({
        patientId: _id,
        start: start,
        end: end,
        types: types,
      })
      .then((res) => {
        return res ? res.data || {} : {};
      })
      .catch(() => {});
  }

  /**
   * 指定のtypeのバイタルを、期間指定で取得するAPI
   */
  async getVitalOutputCSV(patientId: string, start: number, end: number): Promise<any> {
    return this.apiVitalService
      .getPersonalVitalOutPutCSV({
        patientId: patientId,
        start: start,
        end: end,
      })
      .then((res) => {
        return res ? res || {} : {};
      })
      .catch(() => null);
  }


  /**
   * 既にDBに登録されているバイタルデータを削除する処理
   */
  async deleteDBVital(vitalId: string) {
    return this.apiVitalService
    .deleteDBVital(vitalId)
    .then((res) => {
      return res ? res || {} : {};
    })
    .catch(() => null);
  }

  /**
   * DBに登録されているタグ情報を取得する
   */
  async getTagList(): Promise<TagType[]> {
    return this.apiVitalService
      .getApiTaglist()
      .then((res) => {
        return res ? res.tagList : [];
      })
      .catch(() => []);
  }
}

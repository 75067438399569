import { Component, ViewChild, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { IonInfiniteScroll, LoadingController } from '@ionic/angular';
import { PatientService } from '@srcapp/services/master/patient.service';
import { PatientInfoType } from '@srcapp/types/apiResponse.type';
import * as moment from 'moment';

@Component({
  selector: 'app-patient-multi-select-list',
  templateUrl: './patient-multi-select-list.component.html',
  styleUrls: ['./patient-multi-select-list.component.scss'],
})
export class PatientMultiSelectListComponent implements OnInit {
  // 現在選択中の利用者
  @Input() selectedPatientList: PatientInfoType[] | null;
  // @Output() closeEvent = new EventEmitter<any>();
  // - API受信時間
  timestamp: number = 0;

  // **** **** ****
  // * データ管理
  // **** **** ****
  // - 元データ: APIで取得した利用者別のバイタルリスト
  private patientList: any[] = [];
  // - 加工データ: 絞り込みされたデータ:
  private filterPatientList: any[] = [];
  // - 描画データ: 画面描画用のバイタルリスト
  public displayPatientList: any[] = [];
  // 選択中かどうかを高速に判定するためのハッシュ
  private selectedPatientMap: {
    [id: string]: boolean
  } = {};

  // **** **** ****
  // * ページング
  // **** **** ****
  currentIndex:number = 0;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  // **** **** ****
  // * 絞り込み
  // **** **** ****
  //- 当日絞り込み
  targetPatientIdList:{patientId:string}[]|null = null;
  // selectedTargetDate: string = 'all';
  @Input() selectedTargetDate:string = 'all';

  // - 五十音
  kanaList: any = [];
  selectedKana: string = this.patientService.selectedKana;
  // - ユニット絞り込み
  unitList = [];
  @Input() selectedUnit: string = 'all';
  // selectedUnit: string = 'all';
  // - 部屋絞り込み
  roomList = [];
  // selectedRoom:string = 'all';
  @Input() selectedRoom: string = 'all';

  constructor(
    public patientService: PatientService,
    public loadingCtrl: LoadingController,
    public changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.load();
  }

  async load() {

    if(this.selectedPatientList) {
      this.selectedPatientList.map((patient) => {
        this.selectedPatientMap[patient.patientId] = true;
      })
    } else {
      this.selectedPatientList = [];
    }
    this.displayPatientList = [];
    this.filterPatientList = [];

    const loading = await this.loadingCtrl.create({
      message: '読込中...'
    })
    await loading.present();
    // APIでデータ取得
    this.patientList = await this.patientService.getList();
    await loading.dismiss();

    this.timestamp = this.patientService.cacheTimePatientList || moment().unix();

    // グループ情報を作成 (全ての組み合わせを保持)
    this.patientService.listUnitAndRoom(this.patientList);

    // 画面への描画処理
    if (this.selectedTargetDate === 'all') {
      this.showPatient();
    } else {
      this.changeTargetDate();
    }
  }

  async refresh() {
    this.patientService.clearCache();
    this.load();
  }

  // 値が変化したときに呼び出されるライフサイクルメソッド.
  ngOnChanges(changes: SimpleChanges) {
    console.log('値の変更を検知');
    if (changes.selectedPatientList) {
      return ;
    } else if (changes.selectedUnit || changes.selectedRoom) {

      // 設定した内容を記憶しておく
      this.patientService.selectedUnit = this.selectedUnit;
      this.patientService.selectedRoom = this.selectedRoom;
      this.showPatient();
    } else if (changes.selectedTargetDate) {
      this.patientService.selectedTargetDate = this.selectedTargetDate;
      this.changeTargetDate();
    }
  }

  // *****************************************
  // *** 選択処理
  // *****************************************
  select(patient) {
    if (this.selectedPatientMap[patient.patientId] === true) {
      // 選択解除
      this.selectedPatientList = this.selectedPatientList.filter((p) => {
        return p.patientId !== patient.patientId;
      })
    } else {
      // 選択に追加
      this.selectedPatientList.push(patient);
    }
    // toggle
    this.selectedPatientMap[patient.patientId] = !this.selectedPatientMap[patient.patientId];
  }
  isSelected(patientId: string): boolean {
    if (!this.selectedPatientList) return false;
    return this.selectedPatientMap[patientId] === true;
  }
  closeModel() {
    // this.closeEvent.next();
  }
  isFilterSlect(kana: string){
    return this.selectedKana === kana;
  }
  filterKana(kana){
    if (this.selectedKana === kana) {
      this.selectedKana = 'all';
    } else {
      this.selectedKana = kana;
    }
    this.patientService.selectedKana = this.selectedKana;
    this.showPatient();
  }

  // *****************************************
  // *** ページング関連の処理
  // *****************************************
  doInfinite(event) {
    // setTimeout(() => {
      if (!event || !event.target) return ;
      this.addListPatient();
      this.infiniteScroll.complete();
      // event.target.complete();
      if (this.checkIndex()) {
        // this.infiniteScroll.disabled = false;
        // event.target.disabled = false
      } else {
        // this.infiniteScroll.disabled = true;
        // event.target.disabled = true;
      }
    // }, 100);
  }
  private addListPatient() {
    this.currentIndex +=100;
    this.displayPatientList = this.filterPatientList.slice(0, this.currentIndex);
    this.changeDetectorRef.detectChanges();
  }
  checkIndex(){
    return this.filterPatientList.length > this.currentIndex;
  }
  // ****** ****** ****** ここまで: ページング処理 ****** ****** ******

  // *****************************************
  // *** 絞り込み処理を行い利用者を表示する
  // *****************************************
  showPatient() {

    this.filterPatientList = [];

    // グループが変わると最初から描画
    this.currentIndex = 0;
    // スクロールを再開させる
    // this.infiniteScroll.disabled = false;
    // フィルター処理を行う
    const patientList = this.patientService.filterList(this.patientList, "all", this.selectedUnit, this.selectedRoom, this.selectedKana, this.targetPatientIdList);
    if (!patientList) return
    this.filterPatientList = patientList;

    this.addListPatient();
  }

  // *****************************************
  // *** 対象の日付で絞り込み
  // *****************************************
  changeTargetDate() {
    // 日付が選択されていない場合は、検索対象から除外する
    if (!this.selectedTargetDate || this.selectedTargetDate === 'all') {
      this.targetPatientIdList = null;
      this.showPatient();
      return ;
    }

    // APIの実行
    let query = {date: this.selectedTargetDate};
    this.patientService.searchPatient(query).then(patientList => {
      console.log('API実行結果');
      console.log(patientList);
      if (patientList) {
        this.targetPatientIdList = patientList || [];
      } else {
        this.targetPatientIdList = null;
      }
      this.showPatient();
    }).catch((e) => {
      console.log('API実行エラー');
      console.log(e);
    });
  }

}

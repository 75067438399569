import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { AbstractPage } from './../../../pages/abstract-page';

// スタッフ情報
import { StaffInfoType } from '@srcapp/types/apiResponse.type';
import { StaffService } from '@srcapp/services/master/staff.service';

@Component({
  selector: 'app-staff-list-modal',
  templateUrl: './staff-list-modal.component.html',
  styleUrls: ['./staff-list-modal.component.scss'],
})
export class StaffListModalComponent extends AbstractPage implements OnInit {

  staffList: StaffInfoType [] = [];
  @Input() selectedStaff: StaffInfoType | null;
  @Input() timestamp: number;
  @Input() selectTab: string;

  category:string = 'staff';  // staff or datetime

  datetime: string;


  constructor(
    public modalController: ModalController,
    public staffService: StaffService,  
  ) {
    super();
  }

  ngOnInit() {
    this.load();
    console.log(this.selectedStaff);
    console.log(this.timestamp);

    if (this.timestamp) {
      this.datetime = moment.unix(this.timestamp).format("YYYY/MM/DD HH:mm");
    } else {
      this.datetime = moment().format("YYYY/MM/DD HH:mm");
    }

    if (this.selectTab) {
      this.category = this.selectTab;
    }
  }

  async load() {
    this.staffList = [];
    const list = await this.staffService.getList();
    console.log(list);
    this.staffList = list;
  }


  segmentChanged(category) {
    this.category = category;
    console.log(this.category);
  }

  isSelectSegment(category) {
    return this.category === category;
  }


  closeModel() {
    this.modalController.dismiss();
  }

  save() {
    this.modalController.dismiss({
      staff: this.selectedStaff,
      timestamp: moment(this.datetime).unix()
    });
  }


  select(staff: StaffInfoType) {
    console.log('選択中');
    console.log(staff);
    this.selectedStaff = staff;
    // this.modalController.dismiss({staff});
  }

  isSelected(staffId: string): boolean {
    if (!this.selectedStaff) return false;
    return (this.selectedStaff.staffId === staffId);
  }
}


import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStoreService } from '../services/store/auth-store.service';

/**
 * ログイン画面で利用されるGuard処理
 */
@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(public authStoreService: AuthStoreService, public router: Router, public platfor: Platform) {

  }
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
      await this.authStoreService.waitInitialize;

      if (!this.checkSupportBrowser()) {
        alert('Google Chrome (https://www.google.com/intl/ja_jp/chrome/) のブラウザを利用して、アクセスして下さい')
        return false;
      }
  
      // ライセンスの認証がまだの場合は、ライセンスキーのページへ遷移する
      let licenseAuth = this.authStoreService.isLicenseAuth(); // Get the current authentication state from a Service!
      console.log('canActivate', licenseAuth);

      if (licenseAuth) {
        return true;
      } else {
        this.router.navigate(['/license-key']);
        return false;
      }
  }

  private checkSupportBrowser(): boolean {
    if (this.platfor.is('cordova')) return true;
    let isOk = false;
    const userAgent = window.navigator.userAgent.toLowerCase();
    if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
      console.log('Internet Explorerをお使いですね');
    } else if(userAgent.indexOf('edge') != -1) {
      console.log('Edgeをお使いですね');
    } else if(userAgent.indexOf('chrome') != -1) {
      console.log('Google Chromeをお使いですね');
      isOk = true;
    } else if(userAgent.indexOf('safari') != -1) {
      // SafariもOKとしておく
      console.log('Safariをお使いですね');
      isOk = true;
    } else if(userAgent.indexOf('firefox') != -1) {
      console.log('FireFoxをお使いですね');
    } else if(userAgent.indexOf('opera') != -1) {
      console.log('Operaをお使いですね');
    } else {
      console.log('そんなブラウザは知らん');
    }
    return isOk;
  }

  
}

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Subject } from 'rxjs';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import * as moment from 'moment';

const STORAGE_AUTH_NAME = 'storage.auth.info';
const STORAGE_UUID = 'storage.uuid';


type LocalAuthInfo = {
  // ライセンス認証情報
  licenseInfo: LicenseInfoType;
  // ユーザログイン情報
  userInfo: UserInfoType;
};

// {
//   "message":"認証に成功しました。",
//   "statusCode":0,
//   "apiUrl":"https://test-vitaly-app-api.cdc-iot.com",
//   "systemName":"ケアデータコネクト for wiseman",
//   "contractGroupId":"5dd42fcc1a7ca2c6723a77dc",
//   "contractGroupName":"株式会社BRIGHT VIE",
//   "contracts":[
//       {
//         "contractId":"5dd430561a7ca2c6723a77dd",
//         "name":"ブライトヴィー"
//       }
//   ],
// }
type LicenseInfoType = {
  apiUrl: string;
  systemName: string;
  contractGroupId: string;
  contractGroupName: string;
  contracts: {
    contractId: string;
    name: string;
  }[];
};
// {
//   "msg":"success.",
//   "statusCode":0,
//   "contractGroupId":"5dd42fcc1a7ca2c6723a77dc",
//   "contractId":"5dd430561a7ca2c6723a77dd",
//   "contractName":"ブライトヴィー",
//   "staffId":"5dd43235ef16923de4f3d103",
//   "staffName":"藤原　涼",
//   "staffNameKana":"ﾌｼﾞﾜﾗ ﾘｮｳ",
//   "staffLoginId":"fujiwara",
//   "gender":"MAN",
//   "token":"5dd430561a7ca2c6723a77dd_fujiwara_1593313128",
//   "expired":1593917928,
//   "wardName":"",
//   "options":{
//      "sleep":{
//         "nemuri":{
//            "url":"https://test-vitaly-app-api.cdc-iot.com",
//            "sensorUrl":"https://test-vitaly-app-api.cdc-iot.com",
//            "masterUrl":"https://test-vitaly-app-api.cdc-iot.com",
//            "xApiSecret":"sleepNemuriSCANSecret",
//            "xApiToken":"__5dd42fcc1a7ca2c6723a77dc",
//            "makerCode":"PARAMOUNTBED",
//            "makerName":"パラマウントベッド",
//            "productCode":"nemuriSCAN",
//            "productName":"眠りSCAN"
//         }
//      },
//      "nursecall":{
//         "vinurse":{
//            "url":"https://test-vitaly-app-api.cdc-iot.com",
//            "sensorUrl":"https://test-vitaly-app-api.cdc-iot.com",
//            "masterUrl":"https://test-vitaly-app-api.cdc-iot.com",
//            "xApiSecret":"nurseCallVinurseSecret",
//            "xApiToken":"5dd42fcc1a7ca2c6723a77dc",
//            "makerCode":"AIPHONE",
//            "makerName":"アイホン",
//            "productCode":"vinurse",
//            "productName":"Vi-nurse"
//         }
//      }
//   },
//   "menuType":"WSP",
//   "isAdultDayCare":false,
//   "toast":{
//      "message":"ようこそ！藤原　涼さん。",
//      "duration":3000,
//      "position":"top"
//   }
// }
type UserInfoType = {
  contractId: string;
  contractName: string;
  staffId: string;
  staffName: string;
  staffNameKana: string;
  staffIcon: string;
  staffLoginId: string;
  gender: string;
  token: string;
  expired: number;
  menuType: 'WSP' | 'VITALY';
  isAdultDayCare: boolean;
  role?: string;
  wardName?: string;
};

@Injectable({
  providedIn: 'root'
})
export class AuthStoreService {
  uuid: string;

  waitInitialize: Promise<boolean>;

  licenseInfo: LicenseInfoType;
  userInfo: UserInfoType;

  // 固定値
  // - テスト環境
  // authApiUrl = 'https://test-auth-app-api.cdc-iot.com';
  // - 本番環境
  authApiUrl = 'https://auth-app-api.cdc-iot.com';
  // ログイン後のレスポンスで取得する
  baseApiUrl: string;
  token: string;

  // 事業所情報
  contractGroupId: string;
  contractGroupName: string;
  contractId: string;
  contractName: string;

  systemName: string;
  isAdultDayCare: boolean;

  staffId: string;
  staffName: string;
  staffIcon: string;

  menuType?: 'WSP' | 'VITALY';

  loginSource = new Subject<void>();
  login$ = this.loginSource.asObservable();
  logoutSource = new Subject<void>();
  logout$ = this.logoutSource.asObservable();

  constructor(
    private storage: Storage,
    private uniqueDeviceID: UniqueDeviceID,
  ) {
    this.waitInitialize = this.loadAuthInfo();
  }

  // *** *** *** *** *** *** *** ***
  // *** ローカル環境の認証情報を扱う処理
  // *** *** *** *** *** *** *** ***
  /**
   * ローカル環境の認証情報を取得する
   */
  async loadAuthInfo(): Promise<boolean> {
    // UUIDの読み取り
    let uuid = await this.uniqueDeviceID.get().catch(() => '');
    if (!uuid) {
      uuid = await this.storage.get(STORAGE_UUID);
      if (!uuid) {
        uuid = 'W' + Math.floor(Math.random()*100000) + moment().format('YYYYMMDDHHmm')
      }
    }
    this.uuid = uuid;
    await this.storage.set(STORAGE_UUID, uuid);

    this.licenseInfo = null;
    this.userInfo = null;

    const json = await this.storage.get(STORAGE_AUTH_NAME);

    if (json && json.licenseInfo) {
      this.licenseInfo = json.licenseInfo;
      await this.setLicenseInfo(json.licenseInfo);
    }
    if (json && json.userInfo) {
      this.userInfo = json.userInfo;
      await this.setUserInfo(json.userInfo);
    }

    return true;
  }
  /**
   * ローカル環境に認証情報を保存する
   */
  private async saveAuthInfo() {
    const params: LocalAuthInfo ={
      licenseInfo: this.licenseInfo,
      userInfo: this.userInfo
    };
    await this.storage.set(STORAGE_AUTH_NAME, params);
  }
  /**
   * ローカル環境のユーザ情報を削除する(ログアウト)
   */
  async removeAuthUserInfo() {
    const params: LocalAuthInfo ={
      licenseInfo: this.licenseInfo,
      userInfo: null
    };
    this.userInfo = null;

    this.logoutSource.next();
    await this.storage.set(STORAGE_AUTH_NAME, params);
  }
  /**
   * ローカル環境の認証情報を削除する(ライセンスキー削除)
   */
  async removeAuthInfo() {
    const params: LocalAuthInfo ={
      licenseInfo: null,
      userInfo: null
    };
    this.licenseInfo = null;
    this.userInfo = null;
    this.logoutSource.next();
    await this.storage.set(STORAGE_AUTH_NAME, params);
  }

  // *** *** *** *** *** *** *** ***
  // *** ライセンス認証
  // *** *** *** *** *** *** *** ***

  /**
   * ライセンス認証で取得した値をセットする
   */
  async setLicenseInfo(params: LicenseInfoType) {
    console.log(params);
    this.baseApiUrl = params.apiUrl;
    this.contractGroupId = params.contractGroupId;
    this.contractGroupName = params.contractGroupName;
    this.systemName = params.systemName;
    this.licenseInfo = params;
    await this.saveAuthInfo()
  }

  // *** *** *** *** *** *** *** ***
  // *** ユーザ認証
  // *** *** *** *** *** *** *** ***

  /**
   * ユーザの認証情報をセットする
   */
  async setUserInfo(params: UserInfoType) {
    this.contractId = params.contractId;
    this.contractName = params.contractName;
    this.staffId = params.staffId;
    this.staffName = params.staffName;
    this.staffIcon = params.staffIcon;
    this.token = params.token;
    this.isAdultDayCare = params.isAdultDayCare;
    this.menuType = (params.menuType === 'WSP' || params.menuType === 'VITALY') ? params.menuType : 'WSP';
    this.userInfo = params;
    await this.saveAuthInfo();
    this.loginSource.next();
  }

  // *** *** *** *** *** *** *** ***
  // *** 認証情報のセット
  // *** *** *** *** *** *** *** ***

  /**
   * アプリケーションが利用可能な常態か
   */
  isAuth() {
    return (this.licenseInfo && this.userInfo) ? true : false;
  }

  /**
   * ライセンス認証が出来ているか？
   */
  isLicenseAuth() {
    console.log(this.licenseInfo);
    return (this.licenseInfo) ? true : false;
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-news-detail-modal-page',
  templateUrl: './news-detail-modal-page.component.html',
  styleUrls: ['./news-detail-modal-page.component.scss'],
})
export class NewsDetailModalPageComponent implements OnInit {
  @Input() news: any = null;

  constructor(
    public modalCtrl: ModalController,

  ) { }

  ngOnInit() {}

}


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform, LoadingController, ToastController } from '@ionic/angular';
import {  ToastOptions } from '@ionic/core';
import { AuthStoreService } from '@srcapp/services/store/auth-store.service';
import { UtilService } from '@srcapp/services/util.service';
import { Storage } from '@ionic/storage';


// アプリのバージョン
const STORAGE_VERSION_INFO = 'storage.verfion.info';
// アプリのスモールバージョン
const  STORAGE_SMALL_VERSION_INFO = 'storage.small.verfion.info';

@Injectable({
  providedIn: 'root'
})
export class ApiAbstractService {


  isShowVersionUpToast = false;

  appVersion: string;
  appSmallVersion: string;
  public appUUID: string;
  apiTimeoutErrorCount = 0;

  
  constructor(
    public httpClient: HttpClient,
    public plt: Platform,
    public authStoreService: AuthStoreService,
    public loadingCtrl: LoadingController,
    public toastController: ToastController,
    public storage: Storage,
    public utilService: UtilService
    ) {
    this.storage.get(STORAGE_VERSION_INFO).then((appVersion) => {
      this.appVersion = appVersion;
    });
    this.storage.get(STORAGE_SMALL_VERSION_INFO).then((appSmallVersion) => {
      this.appSmallVersion = appSmallVersion;
    });
  }



  getAuthApi() {
    // return 'http://localhost:9800';
    return this.authStoreService.authApiUrl;
  }
  getBaseApi() {
    // return 'http://localhost:9800';
    return this.authStoreService.baseApiUrl;
  }

  async get(url, params = {}) {
    this.errorMessage = null;
    return this.httpClient.get(url).toPromise().then(async (res) => {
      await this.checkUpdateInfo(res);
      return res; 
    }).catch(error => this.httpError(error));
  }

  /**
   * 
   * @param path string ex) /api/v1/login
   */
  async post(url: string, body = {}): Promise<any | null> {
    this.errorMessage = null;

    return this.httpClient.post(url, body).toPromise().then(async (res) => {
      await this.checkUpdateInfo(res);
      return res; 
    }).catch(error => this.httpError(error));
  }

  async delete(url: string): Promise<any | null>  {
    this.errorMessage = null;
    return this.httpClient.delete(url).toPromise().then(async (res) => {
      await this.checkUpdateInfo(res);
      return res; 
    }).catch(error => this.httpError(error));
  }


  public errorMessage;

  async httpError(res): Promise<any | null> {
    if (!res) {
      return null;
    }
    console.log(res);
    console.log(res.status, res.statusText);


    if (res.status === 401) {
      if (res.url.indexOf('/auth/licensekey') >= 0 || res.url.indexOf('/auth/login')  >= 0) {

      } else {
        this.authStoreService.removeAuthUserInfo();
        return null;  
      }
    }

    const title = `通信エラー (${res.status}: ${res.statusText})`;
    let body = res.message;
    if (res.error && res.error.message) {
      body = res.error.message;
    }
    this.errorMessage = body;
    console.log(body);
    return null;
  }

  getQueryString(params = {}) {
    let queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    return queryString;
  }


  /**
   * レスポンスの情報から更新情報がないかをチェック数r
   *
   * - 電話帳のデータを更新する処理
   * - プログラムのアップデートを判定する処理
   *
   * @params res
   */
  private async checkUpdateInfo(res: any): Promise<void> {

    if (!res) return;

    // バージョンアップのチェック
    const versionInfo = res.version;
    if (!versionInfo) {
      return;
    }

    // ローカルに保存しているバージョン情報
    const currentCommitHash = await this.storage.get(STORAGE_SMALL_VERSION_INFO);
    const { forceUpdate, version, commitHash, url, title, msg } = versionInfo;
    // データなければ登録する (初回読み込みと判定し、終了)
    // if (!currentCommitHash) {
    //   await this.saveVersion(version, commitHash);
    //   return;
    // }
    // 通常アップデートで既に表示した場合、何もせず終了
    if (currentCommitHash === commitHash) {
      return;
    }
    // 既に起動中の場合、何もせず終了
    if (this.isShowVersionUpToast) {
      return;
    }
    this.isShowVersionUpToast = true;

    // 強制アップデートの場合は、数秒後に強制的に自動読み込みさせる動きとする
    if (forceUpdate) {
      await this.saveVersion(version, commitHash);

      // アプリの場合は、CodePushでチェックする
      if (this.plt.is('cordova')) {
        this.utilService.updateApplicationSource.next({ forceUpdate: true });
        return;
      }

      // Webの場合は、強制リロードの実施
      setTimeout(async () => {
        const loading = await this.loadingCtrl.create({ message: 'アプリの更新中...' });
        loading.present();
        location.reload();
      }, 10 * 1000);

      const message = 'アプリが更新されました。10秒後に読み込みを開始します。';
      // if (this.plt.is('cordova')) {
      //   message = 'アプリが更新されました。<br>10秒後に読み込みを<br>開始します。';
      // }
      this.presentToast({
        message,
        position: 'bottom',
        buttons: [],
      });
    } else {
      // Webの場合は、更新タイミングを委ねる
      const message = 'アプリが更新されました。<br>入力内容を保存し「更新ボタン」を押してください。';
      // if (this.plt.is('cordova')) {
      //   message = 'アプリが更新されました。<br>入力内容を保存し<br>「更新ボタン」を押して<br>ください。';
      // }
      this.presentToast({
        message,
        position: 'bottom',
        buttons: [
          {
            side: 'end',
            icon: 'notifications-outline',
            text: '更新',
            handler: async () => {
              await this.saveVersion(version, commitHash);

              // アプリの場合は、CodePushでチェックする
              if (this.plt.is('cordova')) {
                this.utilService.updateApplicationSource.next({ forceUpdate: false });
                return true;
              }
              const loading = await this.loadingCtrl.create({ message: 'アプリを更新中...' });
              loading.present();
              location.reload();
              return true;
            },
          },
          {
            // side: 'end',
            role: 'cancel',
            text: '後で',
            handler: () => {
              this.isShowVersionUpToast = false;
            },
          },
        ],
      });
    }
    return;
  }


  async saveVersion(version: string, commitHash: string) {
    this.appVersion = version;
    this.appSmallVersion = commitHash;
    await this.storage.set(STORAGE_VERSION_INFO, version);
    await this.storage.set(STORAGE_SMALL_VERSION_INFO, commitHash);
  }
  async presentToast(options: ToastOptions) {
    const toast = await this.toastController.create(options);
    toast.present();
  }


}

import { Injectable } from '@angular/core';
import { ApiAbstractService } from './api-abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ApiDashboardService extends ApiAbstractService {

  async getTodayData() {
    return this.get(`${this.getBaseApi()}/api/v1/dashboard`);
  }
}
